import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, Collapse, IconButton, IconButtonProps, Skeleton, Step, StepLabel, Stepper, Typography, styled } from "@mui/material";
import dayjs from "dayjs";
import { collection, doc, getDoc, getDocs, limit, query, serverTimestamp, updateDoc, where } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { FormikProps, useFormik } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContextValue, useAuth } from "../../contexts/AuthContext";
import { firestore, storage } from "../../firebase/firebase";
import Navbar from "../components/navbar/Navbar";
import { Child, formIndex, formSubmission } from "../home/Home";
import Loading from "../loading/Loading";
import { Juice, Meal } from "../meals/Meals";
    export default function Form() {
    const navigate=useNavigate();
    const {childID}= useParams();
    const authContext:AuthContextValue=useAuth();
    const [loading,setLoading]=React.useState<boolean>(true);
    const [child,setChild]=React.useState<Child | null>(null);
    const [indexForm,setIndexForm]=React.useState<formIndex | null>(null);
    const [expanded, setExpanded] = React.useState<string>("");
    const [submission,setSubmission]=React.useState<formSubmission | null>(null);
    const [juices,setJuices]=React.useState<Juice[]>([]);
    console.log("indexForm",indexForm);
    const [error,setError]=React.useState<String | null>(null);
    const [selectedDate,setSelectedDate]=React.useState<string>("");
    console.log("selectedDate",selectedDate);
    const formik:FormikProps<{docID:string,selection:{[day:string]:{meal:Meal,juice:Juice}}}>=useFormik({
        initialValues:{
            docID:"",
            selection:{} as {[day:string]:{meal:Meal,juice:Juice}},
        },
        onSubmit:async (values)=>{
            if(values.docID===""){
                window.alert("ERR:F_01\nOPs, something went wrong, please contact support");
                console.error("no docID");
                return;
            }
            if(authContext.currentUser===null){
                window.alert("ERR:F_02\nOPs, something went wrong, please contact support");
                console.error("no currentUser");
                return;
            }
            for(let i=0;i<Object.keys(values.selection).length;i++){
                if(values.selection[Object.keys(values.selection)[i]].meal.docID==="" || values.selection[Object.keys(values.selection)[i]].juice.docID===""){
                    window.alert("Oh no!\nyou have missed a few, please make sure you have selected all your meals");
                    return;
                }
            }
            updateDoc(doc(firestore,"form",values.docID),{
                submittedAt:serverTimestamp(),
                selection:values.selection,
            }).then(()=>{
                navigate("/Home");
            }).catch((error)=>{
                window.alert("ERR:F_03\nOps, something went wrong, please try again later.");
                console.error(error);
            })
        }
    });
    console.log("formik",formik.values);
    React.useEffect(()=>{
      // window.scrollTo({
      //   top: 0,
      //   behavior: "auto",
      // });
      // window.moveTo(0,0);
      window.scrollTo(0,0);
    },[])
    React.useEffect(()=>{
        const fetchData=async ()=>{
            if(authContext.currentUser===null){
                setError("No user ID");
                setLoading(false);
                return;
            }
            if(!childID){
                setError("No child ID");
                setLoading(false);
                return;
            }

            const ChildDoc=await getDoc(doc(firestore,"users",authContext.currentUser.uid,"children",childID));
            if(!ChildDoc.exists()){
                setError("No child found");
                setLoading(false);
                return;
            }
            setChild(ChildDoc.data() as Child);

            const indexFormDoc=await getDoc(doc(firestore,"form","index"));
            if(!indexFormDoc.exists()){
                setError("No form found");
                setLoading(false);
                return;
            }
            const indexForm=indexFormDoc.data() as formIndex;

            setIndexForm(indexForm);

            const q = query(collection(firestore, "form"), where("batch", "==", indexForm.batch), where("parentID", "==", authContext.currentUser?.uid),where("childID","==",childID),limit(1));
            const submissionDocs = await getDocs(q);
            if(submissionDocs.empty){
                setError("Form cannot be submitted for this child this week, if the child has an active subscription please contact us");
                setLoading(false);
                return;
            }
            const submissionDoc=submissionDocs.docs[0];
            const submissionData:formSubmission=submissionDoc.data() as formSubmission;
            if(submissionData.submittedAt){
                // setError("Form already submitted");
                setSubmission(submissionData);
                setLoading(false);
                return;
            }
            if(dayjs(submissionData.weekStart.toDate()).diff(dayjs(),"hour") as number <24){
                setError("Form cannot be submitted for this child this week, default meals will be provided, if you wish to change the meals please contact us");
                setLoading(false);
                return;
            }

            const juicesSnapshot=await getDocs(collection(firestore,"juices"));
            const juicesData:Juice[]=[];
            juicesSnapshot.forEach((juice)=>{
                juicesData.push({...juice.data(),docID:juice.id} as Juice);
            });
            setJuices(juicesData);
            const selection:{[day:string]:{meal:Meal,juice:Juice}}={};
            Object.keys(indexForm.menu).forEach((date)=>{
                selection[date]={meal:{
                  docID:"",
                  allergens:[],
                  name:"",
                  description:"",
                  ingredients:[],
                  imagePath:"", 
                },
                juice:{
                  docID:"",
                  name:"",
                  imagePath:""
                }
              }
            })
            let initialValues:{docID:string,selection:{[day:string]:{meal:Meal,juice:Juice}}}={
                docID:submissionDoc.id,
                selection:selection,
            };
            formik.setValues(initialValues);
            setSelectedDate(dayjs(indexForm.weekStart.toDate()).format("DD-MMM-YY"));
        };

        fetchData().then(()=>{
            setLoading(false);
        })

    },[authContext.currentUser,childID]);
    if(error!==null){
        return(
            <div style={{height:"100svh",overflow:"hidden"}}>
            <Navbar />
            <ErrorDiv>
                <Typography variant="h4">Ops!</Typography>
                <Typography variant="h5" fontWeight="bold" textAlign="center">
                {error}
                </Typography>
                <Button variant="contained" style={{ color: "white" }}
                color="secondary"
                onClick={()=>{
                    navigate("/Home");
                }}
                >
                  Go back
                </Button>
            </ErrorDiv>
            </div>
        );
    }
    if(submission){
      return (
        <>
          <Navbar />
          <MainContainer>
            <Box sx={{ width: "100%" }}>
              <Stepper orientation="vertical">
                {Object.keys(submission.selection)
                  .sort((dateStringA: string, dateStringB: string) => {
                    return dayjs(dateStringA).isBefore(dayjs(dateStringB)) ? -1 : 1;
                  })
                  .map((date) => {
                    return (
                      <Step key={date} index={parseInt(dayjs(date).subtract(1,"day").format("DD"))} active>
                        <StepLabel style={{fontWeight:"bold"}}>
                          <Typography variant='body1' fontWeight="bold">
                        {dayjs(date).format("MMMM")}
                        </Typography>
                        </StepLabel>
                      </Step>
                    );
                  })}
              </Stepper>
            </Box>
          </MainContainer>
        </>
      );
    }
    return (
      <div style={{ height: "100svh" }}>
        {authContext.userInfoLoading ? (
          <Loading fullscreen={true} opacity={0.5} />
        ) : (
          <>
            <Navbar />
            {loading ? (
              <Loading fullscreen={true} opacity={0.5} />
            ) : (
              <MainContainer>
                <DatesGrid>
                  {Object.keys(formik.values.selection)
                    .sort((dateStringA: string, dateStringB: string) => {
                      return dayjs(dateStringA).isBefore(dayjs(dateStringB)) ? -1 : 1;
                    })
                    .map((date) => {
                      return <DateBlock date={date} formik={formik} key={date} selectedDate={selectedDate} />;
                    })}
                </DatesGrid>
                <SectionTitle><Typography variant='h6'>Meals</Typography></SectionTitle>
                <MealsGrid>
                  {indexForm && indexForm.menu[selectedDate] && indexForm.menu[selectedDate].optionA && indexForm.menu[selectedDate].optionB && (
                    <>
                      <MealCard
                        meal={indexForm.menu[selectedDate].optionA}
                        selectedDate={selectedDate}
                        selected={indexForm.menu[selectedDate].optionA.docID === formik.values.selection[selectedDate].meal.docID}
                        formik={formik}
                        key={indexForm.menu[selectedDate].optionA.docID}
                        childAllergies={child?.allergies || []}
                        expanded={expanded}
                        setExpanded={setExpanded}
                      />
                      <MealCard
                        meal={indexForm.menu[selectedDate].optionB}
                        selectedDate={selectedDate}
                        selected={indexForm.menu[selectedDate].optionB.docID === formik.values.selection[selectedDate].meal.docID}
                        formik={formik}
                        key={indexForm.menu[selectedDate].optionB.docID}
                        childAllergies={child?.allergies || []}
                        expanded={expanded}
                        setExpanded={setExpanded}
                      />
                    </>
                  )}
                </MealsGrid>
                <SectionTitle><Typography variant='h6'>Juices</Typography></SectionTitle>
                <JuicesGrid>
                  <div style={{display:"flex",width:"fit-content",gap:"0.5rem",  margin:"0 auto 0 auto",}}> 
                  {
                    juices.map((juice)=>{
                      return <JuiceCard juice={juice} selected={juice.docID === formik.values.selection[selectedDate].juice.docID} selectedDate={selectedDate} formik={formik} key={juice.docID}/>
                    })
                  }
                  </div>
                </JuicesGrid>
                <ActionsDiv>
                  <Button
                    variant="contained"
                    style={{ color: "white" }}
                    color="secondary"
                    disabled={selectedDate === dayjs(indexForm?.weekStart.toDate()).format("DD-MMM-YY")}
                    onClick={() => {
                      const newSelectedDate = Object.keys(formik.values.selection)
                        .sort((dateStringA: string, dateStringB: string) => {
                          return dayjs(dateStringA).isBefore(dayjs(dateStringB)) ? -1 : 1;
                        })
                        .at(
                          Object.keys(formik.values.selection)
                            .sort((dateStringA: string, dateStringB: string) => {
                              return dayjs(dateStringA).isBefore(dayjs(dateStringB)) ? -1 : 1;
                            })
                            .indexOf(selectedDate.toString()) - 1
                        );
                      if (newSelectedDate) {
                        setSelectedDate(newSelectedDate);
                        window.scrollTo(0,0);
                      }
                    }}
                  >
                    Back
                  </Button>
                  {selectedDate === Object.keys(formik.values.selection)
                          .sort((dateStringA: string, dateStringB: string) => {
                            return dayjs(dateStringA).isBefore(dayjs(dateStringB)) ? -1 : 1;
                          })
                          .at(
                            -1
                          ) ? (
                    <Button
                      variant="contained"
                      style={{ color: "white" }}
                      color="primary"
                      disabled={formik.values.selection[selectedDate].meal.docID === "" || formik.values.selection[selectedDate].juice.docID===""}
                      onClick={() => {
                        formik.submitForm();
                      }}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      style={{ color: "white" }}
                      color="secondary"
                      disabled={selectedDate==="" || formik.values.selection[selectedDate]===undefined || formik.values.selection[selectedDate].meal.docID === "" || formik.values.selection[selectedDate].juice.docID===""}
                      onClick={() => {
                        const newSelectedDate = Object.keys(formik.values.selection)
                          .sort((dateStringA: string, dateStringB: string) => {
                            return dayjs(dateStringA).isBefore(dayjs(dateStringB)) ? -1 : 1;
                          })
                          .at(
                            Object.keys(formik.values.selection)
                              .sort((dateStringA: string, dateStringB: string) => {
                                return dayjs(dateStringA).isBefore(dayjs(dateStringB)) ? -1 : 1;
                              })
                              .indexOf(selectedDate.toString()) + 1
                          );
                        if (newSelectedDate) {
                          setSelectedDate(newSelectedDate);
                          setExpanded("");
                          window.scrollTo(0,0);

                        }
                      }}
                    >
                      Next
                    </Button>
                  )}
                </ActionsDiv>
              </MainContainer>
            )}
          </>
        )}
      </div>
    );


}
function JuiceCard({juice,selected,selectedDate,formik}:{juice:Juice,selected:boolean,selectedDate:string,formik:FormikProps<{docID:string,selection:{[day:string]:{meal:Meal,juice:Juice}}}>}){
    const [imageURL,setImageURL]=React.useState<string>("");
    React.useEffect(()=>{
        const storageRef = ref(storage, juice.imagePath);
        getDownloadURL(storageRef).then((url)=>{
            setImageURL(url);
        });
    },[juice.imagePath]);
    return(
        <Card sx={{ width:"10rem" ,height:"fit-content"}} >
            <CardActionArea onClick={()=>{
                formik.setFieldValue("selection",{
                    ...formik.values.selection,
                    [selectedDate]:{
                        ...formik.values.selection[selectedDate],
                        juice:juice,
                    },
                });
            }}
            style={{width:"100%"}}>
            { imageURL!=="" ?
            <CardMedia component="img" alt="avatar" style={{ objectFit: "contain"}} height="140" image={imageURL} />
            :
            <Skeleton variant="rectangular" style={{height:"140px",width:"100%"}} />
        }
        <CardContent>
          <Typography variant="h6" color="text.secondary">{juice.name}</Typography>
          {/* <Typography variant="body2">{juice.description}</Typography> */}
        </CardContent>
        <CardActions disableSpacing >
            {selected ?  <CheckCircleIcon color="secondary" fontSize="large"/>:<span style={{height:"33.4px",width:"33.4px", border:"1px solid lightgrey",borderRadius:"100%"}}/>}
        </CardActions>
        </CardActionArea>
      </Card>
      // <ImageCard style={{backgroundImage:`URL(${imageURL})`}} 
      //   onClick={()=>{
      //     formik.setFieldValue("selection",{
      //         ...formik.values.selection,
      //         [selectedDate]:{
      //             ...formik.values.selection[selectedDate],
      //             juice:juice,
      //         },
      //     });
      //   }}
      // >
      //   <div style={{position:"absolute",
      //   bottom:"0",
      //   left:"0",
      //   padding:"0.2rem",
      //   boxSizing:"border-box",
      //   width:"100%",
      //   height:"30%",
      //   backdropFilter:"brightness(0.9)",
      //   borderRadius:"0.5rem",

      //   }}>
      //     <Typography color="white" fontWeight="bold" variant='h6'>{juice.name}</Typography>   
      //     {/* <Typography color="text.secondary">{juice.description}</Typography>           */}
      //     {selected &&  <CheckCircleIcon color="secondary" style={{backgroundColor:"white",borderRadius:"20rem",padding:"0"}} fontSize="large"/>}
       
      //   </div>
      // </ImageCard>
    );

}

function MealCard({meal,selected,selectedDate,formik,childAllergies,expanded,setExpanded}:{meal:Meal,selected:boolean,selectedDate:string,formik:FormikProps<{docID:string,selection:{[day:string]:{meal:Meal,juice:Juice}}}>,childAllergies:string[],expanded:string,setExpanded:Function}){
    const [imageURL,setImageURL]=React.useState<string>("");

    const handleExpandClick = () => {
      setExpanded(expanded===meal.docID?"":meal.docID);
    };
    React.useEffect(()=>{
        const storageRef = ref(storage, meal.imagePath);
        getDownloadURL(storageRef).then((url)=>{
            setImageURL(url);
        });
    },[meal.imagePath]);
    const allergyCompatible=React.useMemo(()=>{
        return meal.allergens.reduce((acc,allergen)=>{
            return acc || childAllergies.includes(allergen);
        },false);
    },[childAllergies,meal.allergens])
    return(
        <Card sx={{ width:"clamp(10rem,100%,30rem)" ,height:"fit-content"}}>
            <CardActionArea onClick={()=>{
            // setExpanded(false);
            formik.setFieldValue("selection",{
                ...formik.values.selection,
                [selectedDate]:{
                    ...formik.values.selection[selectedDate],
                    meal:meal,

                },
            });
            }}
            disabled={allergyCompatible}
            style={{filter: allergyCompatible ? "brightness(0.8)" : "none",backgroundColor:allergyCompatible?"#ededed":"white"}}
            >
       { imageURL!=="" ?
            <CardMedia component="img" alt="avatar" style={{ objectFit: "cover"}} height="140" image={imageURL} />
            :
            <Skeleton variant="rectangular" style={{height:"140px",width:"100%"}} />
        }
        <CardContent>
          <Typography variant="h6" color="text.secondary">{meal.name}</Typography>
          <div style={{display:"flex"}}>
          {meal.allergens.map((allergen)=>{
              return <Chip color={
                  childAllergies.includes(allergen)?"error":"primary"
              } variant={
                childAllergies.includes(allergen)?"filled":"outlined"
            }  label={allergen} style={{margin:"0.1rem",fontWeight:"bold",textTransform:"capitalize"}}/>
          })}
          {meal.allergens.length===0 && <Chip color="secondary" label="No allergens" style={{margin:"0.25rem",color:"white",fontWeight:"bold"}}/>}
          </div>
          {allergyCompatible && <Typography variant='body1' color="error" fontWeight="bold">The meal contains allergens not suitable for this child</Typography>}
        </CardContent>
        <CardActions disableSpacing >
        {selected ?  <CheckCircleIcon color="secondary" fontSize="large"/>:<span style={{height:"33.4px",width:"33.4px", border:"1px solid lightgrey",borderRadius:"100%"}}/>}
           
          <ExpandMore
            expand={expanded===meal.docID}
            onTouchStart={(event) => event.stopPropagation()}
            onMouseDown={e => e.stopPropagation()}
            onClick={(e)=>{
                e.stopPropagation();
              e.preventDefault();
                handleExpandClick();
            }}
            aria-expanded={expanded===meal.docID}
            aria-label="show more"
          >
            <ExpandMoreIcon  style={{color:"white"}}/>
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded===meal.docID} timeout="auto" unmountOnExit>
          <CardContent>
             <Typography variant="body2">{meal.description}</Typography>
             <Typography variant="body2" component="div"><Typography fontWeight="bold" variant="body2">ingredients:</Typography>{meal.ingredients.toString()}</Typography>
             <Typography variant="body2"> <Typography fontWeight="bold" variant="body2">allergens:</Typography>{meal.allergens.toString()}</Typography>
          </CardContent>
        </Collapse>
        </CardActionArea>
      </Card>

    );
}

function DateBlock({date,formik,selectedDate}:{date:string,selectedDate:string,formik:FormikProps<{docID:string,selection:{[day:string]:{meal:Meal,juice:Juice}}}>}){
    return(
        <DateBlockContainer done={formik.values.selection[date].meal.docID!==""} selected={selectedDate===date}>
            <Typography fontWeight="bold"> {dayjs(date).format("DD\nMMM")} </Typography> 

        </DateBlockContainer>
    );

}
interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }
  
  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    // border: "1px solid #ed9a48 ",
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    ":hover":{
        backgroundColor:"#b37d48",
    }
  }));
const MainContainer=styled("div")({
  padding:"1rem",
  gap:"1.5rem",
  boxSizing:"border-box",
  display:"flex",
  flexDirection:"column",
  alignItems:"center"
});
const DatesGrid=styled("div")({
    display:"grid",
    width:"clamp(10rem,100%,30rem)",
    justifyItems:"center",
    justifyContent:"space-around",
    gridTemplateColumns:"repeat(5,min(10vw,100px))",
    gap:"3%",
    paddingTop:"1rem",

})
const MealsGrid=styled("div")({
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    width:"clamp(10rem,100%,70rem)",
    justifyContent:"center",
    gap:"1.5rem",
    // paddingTop:"5vh",
    "@media (min-width: 600px)": {
      flexDirection:"row",
      flexWrap:"wrap",
      justifyContent:"space-around",
    }
});

const JuicesGrid=styled("div")({
  width:"clamp(10rem,100%,70rem)",
  overflowX:"scroll",
  "-webkit-scrollbar":{
    display:"none",
  },
  "-ms-overflow-style":{
    display:"none",
  },
  scrollbarWidth:"none",
  padding:"0.5rem",
  boxSizing:"border-box",
      "@media (max-width: 1015px)": {
        width:"clamp(10rem,100%,30rem)",
        "-webkit-scrollbar":{
          display:"initial",
        },
        "-ms-overflow-style":{
          display:"initial",
        },
        scrollbarWidth:"initial",


    }


})
const DateBlockContainer=styled("div")<{done?:boolean,selected?:boolean}>(({ done,selected,theme }) => ({
    padding:"0.5rem",
    width:"clamp(3rem, 7vh, 5rem)",
    height:"clamp(3rem, 7vh, 5rem)",
    // aspectRatio:"1/1",
    borderRadius:"0.3rem",
    // height:"100%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    backgroundColor:selected? theme.palette.secondary.main :done? theme.palette.primary.main : theme.palette.grey[300],
    color:"white",
    textAlign:"center",
    boxSizing:"border-box",
  }));
  
const ActionsDiv=styled("div")({
    display:"flex",
    justifyContent:"space-around",
    alignItems:"center",
    width:"clamp(10rem,100%,30rem)",
    gap:"1rem",
    paddingTop:"1rem",
});

const ErrorDiv=styled("div")(({theme})=>({
    width:"100%",
    height:"100%",
    backgroundColor:theme.palette.error.light,
    color:theme.palette.error.contrastText,
    display:"flex",
    flexDirection:"column",
    gap:"1rem",
    justifyContent:"center",
    alignItems:"center",
}));
const SectionTitle=styled('div')(({ theme }) => ({
  width:"clamp(10rem,100%,30rem)",
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  ':after':{
    content:"''",
    flex:"1",
    borderBottom:"4px solid black",
    margin:"0 1rem",
    borderRadius:"0.5rem",
    borderColor:theme.palette.primary.main
  },
  ':before':{
    content:"''",
    flex:"1",
    borderBottom:"4px solid black",
    margin:"0 1rem",
    borderRadius:"0.5rem",
    borderColor:theme.palette.primary.main
  }
}));
