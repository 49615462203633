import { Outlet, Navigate } from "react-router-dom";
import { AuthContextValue, useAuth } from "../contexts/AuthContext";
import Loading from "../pages/loading/Loading";
export default function AdminRoutes() {
  const authContext:AuthContextValue=useAuth();
  return (
    <>
    {
      authContext.userInfoLoading ? <Loading fullscreen/> :
      authContext.currentUserInfo?.role==="admin" ?  <Outlet />  : <Navigate to="/" />
    }
    </>
    ) ;
}
