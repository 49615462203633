import { Timestamp } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import Navbar from "../components/navbar/Navbar";
import Loading from "../loading/Loading";
import { Juice, Meal } from "../meals/Meals";
import Children from "./components/Children";
import Hero from "./components/Hero";

export default function Home() {
    const authContext=useAuth();
    return(
        <div style={{height:"100vh",paddingBottom:"5rem"}}>
        {
            authContext.userInfoLoading ? <Loading fullscreen={true} opacity={0.5}/> :       
            <>  
            <Navbar/>
            <Hero/>
            <Children/>
            </>
        }
        </div>
    );
}

export type Child={
    docID:string,
    name:string,
    school:string,
    grade:string,
    gender:number,
    allergies:string[],
    DOB:Timestamp,
    avatar:string,
    activeSubscription:boolean,
    parentID:string,
    parentName:string,
    parentPhoneNumber:string,
    address:{
        city:string;
        block:string;
        road:string;
        building:string;
        flat?:string;
    }
}

export type selection={
    mealID:string;
    mealName:string;
}


export type formSubmission = {
    parentID: string;
    childID: string;
    childName: string;
    parentName: string;
    parentPhone: string;
    school: string;
    grade: string;
    batch: number;
    selection: {
      [day: string]: {
        meal:Meal,
        juice:Juice,
      };
    };
    address: {
      city: string;
      block: string;
      road: string;
      building: string;
      flat?: string;
    };
    weekStart: Timestamp;
    weekEnd: Timestamp;
    submittedAt?: Timestamp;
  };
export type formIndex={
    batch:number,
    menu:{
        [date:string]:{
            optionA:Meal,
            optionB:Meal,
            juicess:Juice[],
        }
    }
    weekStart:Timestamp,
    weekEnd:Timestamp,
    holidays:number[]
}