import React from 'react'
import { Button, Card, CardContent, Divider, Fab, FormControl, InputLabel, MenuItem, Select, Skeleton, TextField, Typography, styled } from "@mui/material";
import Navbar from "../components/navbar/Navbar";
import { formIndex, formSubmission } from '../home/Home';
import { query, collection, where, limit, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebase/firebase';
import dayjs from 'dayjs';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { Options, Resolution, usePDF } from 'react-to-pdf';
import PrintIcon from '@mui/icons-material/Print';
const pdfOptions:Options={
  resolution:Resolution.HIGH,
  canvas:{
    qualityRatio:2,
  }
}
export default function AdminPrintLabels() {
    const [batch,setBatch]=React.useState<number>(-1);
    const [loading,setLoading]=React.useState<boolean>(true);
    const [indexForm,setIndexForm] =React.useState<formIndex>();
    const [selectedDate,setSelectedDate]=React.useState<string>("");
    const [submissions,setSubmissions]=React.useState<formSubmission[]>([]);
    const { toPDF, targetRef } = usePDF({filename: 'Food Labels.pdf'});
    React.useEffect(()=>{
        const fetchData=async()=>{
            try{
                const indexFormRef=doc(firestore,"form","index");
                const indexFormDoc=await getDoc(indexFormRef);
                const indexFormData=indexFormDoc.data() as formIndex;
                setIndexForm(indexFormData);
                setBatch(indexFormData.batch);
                setLoading(false);
            }catch(error){
                window.alert("Error fetching data 3");
            }
        };
        fetchData();
    },[]);
    React.useEffect(() => { 
        if(batch===-1){
            return;
        }
        const q = query(collection(firestore, "form"), where("batch", "==", batch),limit(150));
        setLoading(true);
        const unsubscribe = onSnapshot(q, async (snapshot) => {
          const promises: formSubmission[] = [];
          snapshot.docs.forEach((doc) => {
            if (doc.id !== "index") {
              promises.push(doc.data() as formSubmission);
            }
          });
          const submissions = await Promise.all(promises);
          if(submissions.length>140){
            window.alert("only showing 150 submissions\nplease contact dev team")
          }
          setSubmissions(submissions);
          setLoading(false);
        });
        return () => {
          console.log("unsubscribing");
          unsubscribe();
        };
        }, [batch]);
    return (
      <>
        <Navbar />
        <MainContainer >
          {loading ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          ) : (
            <div style={{ display: "flex", gap: "0.5rem", width: "35%" }}>
              <FormControl fullWidth>
                <InputLabel id="dateSelect">Select Date</InputLabel>
                <Select labelId="dateSelect" label="Select Date" onChange={(e)=>{
                    setSelectedDate(e.target.value as string);
                }}
                value={selectedDate}>
                  {indexForm &&
                    Object.keys(indexForm.menu)
                      .sort((dateStringA: string, dateStringB: string) => {
                        return dayjs(dateStringA).isBefore(dayjs(dateStringB)) ? -1 : 1;
                      })
                      .map((date) => {
                        return <MenuItem key={date} value={date}>{date}</MenuItem>;
                      })}
                </Select>
              </FormControl>
            </div>
          )}
          <div ref={targetRef} style={{display:"flex",flexDirection:"row",gap:"5rem",padding:"1rem",paddingTop:"5rem",paddingBottom:"3rem",flexWrap:"wrap",height:"100%",justifyContent:"center",alignItems:"center",width:"1423.200px"} }>
          {
           selectedDate && submissions.map((submission)=>{
                return <Label submission={submission} selectedDate={selectedDate} key={`${submission.childID}-${submission.batch}`}/>;
            })
          }
          </div>
          <Fab variant='extended' onClick={()=>{toPDF(pdfOptions)}}  color='primary' style={{position:"fixed",bottom:"2rem",right:"2rem",color:"white",display:"flex",gap:"0.2rem"}}><PrintIcon style={{color:"white"}}/> print PDF</Fab>
        </MainContainer>
      </>
    );
}

function Label({submission,selectedDate}:{submission:formSubmission,selectedDate:string}){

    return (
            <LabelCard>
        <CardContent>
          <Typography variant="h5" textAlign="center" textTransform="uppercase" fontWeight="bold" fontSize="2rem">
            {submission.selection[selectedDate].meal.name}
          </Typography>
          <Divider variant="fullWidth" style={{ borderWidth: "0.2rem", borderRadius: "1rem", margin: "0.2rem 0 0.2rem 0" }} />
          <Typography variant="body1" fontWeight="bold" fontSize="1.2rem">
            Ingredients:
          </Typography>
          <Typography variant="body1"  fontSize="1.2rem">{submission.selection[selectedDate].meal.ingredients.join(", ")}</Typography>
          <Typography variant="body1" fontWeight="bold" fontSize="1.2rem">
            Allergens:
          </Typography>
          <Typography variant="body1"  fontSize="1.2rem" style={{ textTransform: "capitalize" }}>
            {submission.selection[selectedDate].meal.allergens.length > 0 ? submission.selection[selectedDate].meal.allergens.join(", ") : "No specific allergens"} | Our food may contain or have come in contact with wheat, soy, eggs or dairy products
          </Typography>
          <Divider variant="fullWidth" style={{ borderWidth: "0.2rem", borderRadius: "1rem", margin: "0.2rem 0 0.2rem 0" }} />
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <div style={{ display: "flex", flexDirection: "column",width:"50%" }}>
              <Typography variant="body1" fontWeight="bold">
                Produced by
              </Typography>
              <Typography variant='body1'  fontSize="1.2rem">Lunchy</Typography>
              <Typography variant="body1" fontWeight="bold">
                Packed on 
              </Typography>
              <Typography variant='body1'  fontSize="1.2rem">{dayjs().format("DD MMMM YYYY")}</Typography>
            </div>
            <Divider orientation="vertical" flexItem style={{ borderWidth: "0.2rem", borderRadius: "1rem" }} />
            <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
            <Typography variant="body1" fontWeight="bold">
              Storage Instructions
            </Typography>
            <Typography variant="body1"  fontSize="1.2rem">To be consumed within 2 days from production date.</Typography>

            <Typography style={{display:"flex",alignItems:"flex-start",gap:"0.2rem"}}  fontSize="1.2rem"><AcUnitIcon style={{color:"black"}} />Refrigerate below 4°C</Typography>
            <Typography style={{display:"flex",alignItems:"flex-start",gap:"0.2rem"}}  fontSize="1.2rem"><MicrowaveIcon style={{color:"black"}} />Microwave for 1-2 min to serve hot</Typography>
            </div>
          </div>
        </CardContent>
      </LabelCard>
    );
};


// function Label({submission,selectedDate}:{submission:formSubmission,selectedDate:string}){

//   return (
//     // <Card style={{ display: "flex", flexDirection: "column", width: "420px" ,height:"340px"}}>
//           <Card style={{ display: "flex", flexDirection: "column", width: "620px" ,height:"340px",scale:"1.1",border:"1px solid black"}}>

//       <CardContent>
//         <Typography variant="h5" textAlign="center" textTransform="uppercase" fontWeight="bold">
//           {submission.selection[selectedDate].meal.name}
//         </Typography>
//         <Divider variant="fullWidth" />
//         <Typography variant="body1" fontWeight="bold">
//           Ingredients:
//         </Typography>
//         <Typography variant="body1" color="text.secondary" fontSize="0.8rem">{submission.selection[selectedDate].meal.ingredients.join(", ")}</Typography>
//         <Typography variant="body1" fontWeight="bold">
//           Allergens:
//         </Typography>
//         <Typography variant="body1" color="text.secondary" fontSize="0.8rem" style={{ textTransform: "capitalize" }}>
//           {submission.selection[selectedDate].meal.allergens.length > 0 ? submission.selection[selectedDate].meal.allergens.join(", ") : "No specific allergens"} | Our food may contain or have come in contact with wheat, soy, eggs or dairy products
//         </Typography>
//         <Divider variant="fullWidth" style={{ borderWidth: "0.2rem", borderRadius: "1rem", margin: "0.2rem 0 0.2rem 0" }} />
//         <div style={{ display: "flex", gap: "0.5rem" }}>
//           <div style={{ display: "flex", flexDirection: "column",width:"50%" }}>
//             <Typography variant="body1" fontWeight="bold">
//               Produced by
//             </Typography>
//             <Typography variant='body1' color="text.secondary" fontSize="0.8rem">Lunchy</Typography>
//             <Typography variant="body1" fontWeight="bold">
//               Packed on 
//             </Typography>
//             <Typography variant='body1' color="text.secondary" fontSize="0.8rem">{dayjs().format("DD MMMM YYYY")}</Typography>
//           </div>
//           <Divider orientation="vertical" flexItem style={{ borderWidth: "0.2rem", borderRadius: "1rem" }} />
//           <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
//           <Typography variant="body1" fontWeight="bold">
//             Storage Instructions
//           </Typography>
//           <Typography variant="body1" color="text.secondary" fontSize="0.8rem">To be consumed within 2 days from production date.</Typography>

//           <Typography style={{display:"flex",alignItems:"flex-start",gap:"0.2rem"}} color="text.secondary" fontSize="0.8rem"><MicrowaveIcon style={{color:"black"}} />Microwave for 1-2 min to serve hot</Typography>
//           <Typography style={{display:"flex",alignItems:"flex-start",gap:"0.2rem"}} color="text.secondary" fontSize="0.8rem"><AcUnitIcon style={{color:"black"}} />Refrigerate below 4°C</Typography>
//           </div>
//         </div>
//       </CardContent>
//     </Card>
//   );
// };

const MainContainer=styled("div")({
    display:"flex",
    flexDirection:"column",
    justifyContent:"flex-start",
    alignItems:"flex-start",
    width:"100%",
    height:"100%",
    padding:"2rem",
    boxSizing:"border-box",
});

 
const LabelCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  width: "620px",
  height: "400px",
  scale: "1.1",
  border: "1px solid black",
  borderRadius: "0",
  ":nth-child(8n+8)": {
    marginBottom: "8.33rem",
  },
  ":nth-child(8n+7)": {
    marginBottom: "8.33rem",
}
});