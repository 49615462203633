import HistoryIcon from '@mui/icons-material/History';
import { Autocomplete, Button, Card, CardActions, CardContent, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Fab, Skeleton, TextField, Typography, styled } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { collection, doc, endAt, getDoc, getDocs, increment, orderBy, query, startAt, updateDoc, where } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { FormikProps, useFormik } from "formik";
import React from "react";
import { firestore, storage } from "../../firebase/firebase";
import useDebounce from "../../hooks/UseDebounce";
import Navbar from "../components/navbar/Navbar";
import { formIndex } from "../home/Home";
import { Meal } from "../meals/Meals";

export default function AdminNewForm() {
    const [openPreviousMenu, setOpenPreviousMenu] = React.useState(false);
    const [loadedMeals,setLoadedMeals]=React.useState<{loading:boolean,meals:Meal[]}>({
        loading:true,
        meals:[]
    });
    const [submitting,setSubmitting]=React.useState(false);
    React.useEffect(()=>{
        const mealsRef=collection(firestore,"meals");
        getDocs(mealsRef).then((snapshot)=>{
            const meals:Meal[]=[];
            snapshot.forEach((doc)=>{
                meals.push({...doc.data(),docID:doc.id} as Meal);
            });
            setLoadedMeals({
                loading:false,
                meals
            });
        }).catch((error)=>{
            window.alert("Error fetching meals");
            console.log(error);
        })
    },[]);

    const newFormFormik:FormikProps<{
        weekStart:dayjs.Dayjs,
        weekEnd:dayjs.Dayjs,
        menu:{
            [date:string]:{
                optionA:Meal,
                optionB:Meal,
            }
        },
        holidays:string[]
    
    }>=useFormik({
        initialValues:{
            weekStart:dayjs().add(7,"day").startOf("day").startOf("week"),
            weekEnd:dayjs().add(7,"day").startOf("day").endOf("week"),
            menu:{} as {[date:string]:{optionA:Meal,optionB:Meal}},
            holidays:[] as string[]
        },
        onSubmit:(values)=>{
            console.log("New form formik submitted");
            console.log(values);
            let numberofDays=values.weekEnd.diff(values.weekStart,"day")+1;
            numberofDays-=values.holidays.length;
            if(numberofDays<5){
                window.alert("Differnce between start and end of week must be at least 5 days");
                return;
            }
            if(Object.keys(values.menu).length===0){
                window.alert("Please select at least one meal");
                return;
            }

            Array.from({ length: newFormFormik.values.weekEnd.diff(newFormFormik.values.weekStart, "day") + 1 }).forEach((_, index) => {
              const currentDate = newFormFormik.values.weekStart.add(index, "day");
              const formattedDate = currentDate.format("DD-MMM-YY");
              if (
                newFormFormik.values.holidays.includes(formattedDate) ||
                currentDate.day() === 5 || // Friday
                currentDate.day() === 6 // Saturday
              ) {
                
                  numberofDays--;
              }
            })
            if(Object.keys(values.menu).length!==numberofDays){
                window.alert("Please select a meal for each day");
                return;
            }
            for (let i=0;i<Object.keys(values.menu).length;i++){
                const day=Object.keys(values.menu)[i];
                if(!values.menu[day]?.optionA || !values.menu[day]?.optionB){
                    window.alert(day+"\nPlease select both meal A & B for each day");
                    return;
                }
                if(values.menu[day].optionA.allergens.length>0){
                    window.alert(day+"\nOption A meals cannot have allergens");
                    return;
                }
                if(values.menu[day].optionB.docID===values.menu[day].optionA.docID){
                    window.alert(day+"\nOption A & B cannot be the same");
                    return;
                }
            }
            setSubmitting(true);
            const indexRef=doc(firestore,"form","index");
            updateDoc(indexRef,{
                batch:increment(1),
                weekStart:values.weekStart.hour(0).minute(0).second(0).toDate(),
                weekEnd:values.weekEnd.hour(0).minute(0).second(0).toDate(),
                menu:values.menu,
                holidays:values.holidays
            }).then(()=>{

                window.alert("Form created successfully");
                newFormFormik.resetForm();
            }).catch((error)=>{
                window.alert("Error creating form");
                console.log(error);
            }).finally(()=>{
                setSubmitting(false);
            })
            
        }
    
    })
    return (
      <>
      <Navbar/>
    <MainContainer>
        <Card style={{ width: "100%", height: "100%" }} component="form" onSubmit={(e)=>{
            e.preventDefault();
            newFormFormik.handleSubmit();
        }}>
          <CardContent style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Create new form
            </Typography>
            <Divider />
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Timing
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{ display: "flex", width: "70%", justifyContent: "space-between", gap: "2rem" }}>
                <div style={{width:"100%"}}>
                <DatePicker
                  label="Start of Week"
                  value={newFormFormik.values.weekStart}
                  onChange={(newValue) => {
                    if(Object.keys(newFormFormik.values.menu).length>0){
                        if(!window.confirm("Changing the start of week will reset the menu. Are you sure you want to continue?")){
                            return;
                        }
                    }
                    newFormFormik.setFieldValue("weekStart", newValue);
                    newFormFormik.setFieldValue("menu",{});
                    newFormFormik.setTouched({ weekStart: true });
                  }}
                  onClose={() => {
                    newFormFormik.handleBlur("weekStart");
                    newFormFormik.setTouched({ weekStart: true });
                  }}
                  disablePast
                  format="DD-MMM-YYYY"
                  slotProps={{
                    textField: {
                      //@ts-ignore
                      helperText: newFormFormik.touched.weekStart && newFormFormik.errors.weekStart,
                      error: newFormFormik.touched.weekStart && Boolean(newFormFormik.errors.weekStart),
                      fullWidth: true,
                    },
                  }}
                />
                <Typography variant='body2' color="text.secondary"> {(newFormFormik.values.weekStart.hour(0).minute(0).second(0).subtract(1,"day")).format("DD-MMM @ HH:mm")} would be the last day to submit </Typography>
                </div>
                <DatePicker
                  label="End of Week"
                  value={newFormFormik.values.weekEnd}
                  onChange={(newValue) => {
                    if(Object.keys(newFormFormik.values.menu).length>0){
                        if(!window.confirm("Changing the end of week will reset the menu. Are you sure you want to continue?")){
                            return;
                        }
                    }
                    newFormFormik.setFieldValue("weekEnd", newValue);
                    newFormFormik.setFieldValue("menu",{});
                    newFormFormik.setTouched({ weekEnd: true });
                  }}
                  onClose={() => {
                    newFormFormik.handleBlur("weekEnd");
                    newFormFormik.setTouched({ weekEnd: true });
                  }}
                shouldDisableDate={(day) => {
                    const formattedDate = day.format("DD-MMM-YY");
                    return (
                    day.isSame(newFormFormik.values.weekStart, "day") ||
                    day.isBefore(newFormFormik.values.weekStart) ||
                    newFormFormik.values.weekStart.diff(day, "day") < -15 ||
                      newFormFormik.values.holidays.includes(formattedDate)
                    );
                  
                }}
                //   disablePast
                  format="DD-MMM-YYYY"
                  slotProps={{
                    textField: {
                      //@ts-ignore
                      helperText: newFormFormik.touched.weekEnd && newFormFormik.errors.weekEnd,
                      error: newFormFormik.touched.weekEnd && Boolean(newFormFormik.errors.weekEnd),
                      fullWidth: true,
                    },
                  }}
                />
              </div>
            </LocalizationProvider>
            <Button
              style={{ width: "fit-content" }}
              onClick={() => {
                console.warn("TODO:: implement");
              }}
              disabled
            >
              add holiday
            </Button>

            <Divider />

            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Menu
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Select meals for each day
            </Typography>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {Array.from({ length: newFormFormik.values.weekEnd.diff(newFormFormik.values.weekStart, "day") + 1 }).map((_, index) => {
                const currentDate = newFormFormik.values.weekStart.add(index, "day");
                const formattedDate = currentDate.format("DD-MMM-YY");
                if (
                  newFormFormik.values.holidays.includes(formattedDate) ||
                  currentDate.day() === 5 || // Friday
                  currentDate.day() === 6 // Saturday
                ) {
                    return null;
                }
                return <Selection date={currentDate} newFormFormik={newFormFormik} loadedMeals={loadedMeals} key={currentDate.date()}/>;

              })}
            </div>
          </CardContent>
          <CardActions>
            <Button type="submit" variant="contained" color="primary"  style={{ width: "fit-content",color:"white" }} disabled={submitting}>
              Create
            </Button>
          </CardActions>
        </Card>
        <Fab
          variant="extended"
          onClick={() => {
            setOpenPreviousMenu(true);
          }}
          style={{ position: "fixed", bottom: "1rem", right: "1rem",display:"flex",gap:"0.5rem" }}
          color='primary'
        >
          <Typography color="white">Current Form</Typography>
          <HistoryIcon style={{color:"white"}}/>
        </Fab>
        <PreviousMenu open={openPreviousMenu} setOpen={setOpenPreviousMenu} />
      </MainContainer>
      </>
    );
}
function Selection({date,newFormFormik,loadedMeals}:{date:Dayjs,loadedMeals:{
    loading:boolean,
    meals:Meal[]
},newFormFormik:FormikProps<{weekStart:dayjs.Dayjs,weekEnd:dayjs.Dayjs,menu:{[date:string]:{
    optionA:Meal,
    optionB:Meal,
}},holidays:string[]}>})
{
    const [optionAValue,setOptionAValue]=React.useState<Meal | null>(null);
    const [optionBValue,setOptionBValue]=React.useState<Meal | null>(null);
    const [optionAOptions,setOptionAOptions]=React.useState<Meal[]>(loadedMeals.meals);
    const [optionBOptions,setOptionBOptions]=React.useState<Meal[]>(loadedMeals.meals);
    const [optionAInputValue,setOptionAInputValue]=React.useState<string>("");
    const [optionBInputValue,setOptionBInputValue]=React.useState<string>("");
    const [optionAOpen,setOptionAOpen]=React.useState<boolean>(false);
    const [optionBOpen,setOptionBOpen]=React.useState<boolean>(false);
    const [optionALoading,setOptionALoading]=React.useState<boolean>(false);
    const [optionBLoading,setOptionBLoading]=React.useState<boolean>(false);
    const debouncedOptionAInputValue=useDebounce(optionAInputValue,500);
    const debouncedOptionBInputValue=useDebounce(optionBInputValue,500);
    React.useEffect(()=>{
        if(!loadedMeals.loading){
            setOptionAOptions(loadedMeals.meals);
            setOptionBOptions(loadedMeals.meals);
        }
    },[loadedMeals.loading]);
    React.useEffect(()=>{
        if(debouncedOptionAInputValue===""){
            setOptionAOptions(loadedMeals.meals);
            return;
        }
        setOptionALoading(true);
        if(
            loadedMeals.meals.filter((meal)=>meal.name.toLowerCase().includes(debouncedOptionAInputValue.toLowerCase())).length>0
        ){
            setOptionAOptions(loadedMeals.meals);
            setOptionALoading(false);
            return;
        }
        const mealsRef=collection(firestore,"meals");
        const q=query(mealsRef,where("name",">=",debouncedOptionAInputValue),where("name","<=",debouncedOptionAInputValue+"\uf8ff"));
        getDocs(q).then((snapshot)=>{
            const meals:Meal[]=[];
            snapshot.forEach((doc)=>{
                meals.push({...doc.data(),docID:doc.id} as Meal);
            });
            setOptionAOptions(meals);
            setOptionALoading(false);
        }).catch((error)=>{
            window.alert("Error fetching meals");
            console.log(error);
        })
    },[debouncedOptionAInputValue])

    React.useEffect(()=>{
        if(debouncedOptionBInputValue===""){
            setOptionBOptions(loadedMeals.meals);
            return;
        }
        setOptionBLoading(true);
        if(
            loadedMeals.meals.filter((meal)=>meal.name.toLowerCase().includes(debouncedOptionBInputValue.toLowerCase())).length>0
        ){
            setOptionBOptions(loadedMeals.meals);
            setOptionBLoading(false);
            return;
        }
        const mealsRef=collection(firestore,"meals");
        const q=query(mealsRef,startAt(debouncedOptionBInputValue),endAt(debouncedOptionBInputValue+"\uf8ff"),orderBy("name"));
        getDocs(q).then((snapshot)=>{
            const meals:Meal[]=[];
            snapshot.forEach((doc)=>{
                meals.push({...doc.data(),docID:doc.id} as Meal);
            });
            setOptionBOptions(meals);
            setOptionBLoading(false);
        }).catch((error)=>{
            window.alert("Error fetching meals");
            console.log(error);
        })
    },[debouncedOptionBInputValue])


    React.useEffect(()=>{
        if(newFormFormik.values.menu[date.format("DD-MMM-YY")]){
            if(newFormFormik.values.menu[date.format("DD-MMM-YY")].optionA){
            setOptionAValue(newFormFormik.values.menu[date.format("DD-MMM-YY")].optionA);
        }
        if(newFormFormik.values.menu[date.format("DD-MMM-YY")].optionB){
            setOptionBValue(newFormFormik.values.menu[date.format("DD-MMM-YY")].optionB);
        }
        }else{
            setOptionAValue(null);
            setOptionBValue(null);
        }
    },[newFormFormik.values.menu]);
    
    return (
      <div style={{ display: "flex", gap: "1rem", alignItems: "flex-start", flexDirection: "column" }}>
        <Typography variant="body1" fontWeight="bold">
          {date.format("dddd, DD MMM")}
        </Typography>
        <div style={{display:"flex",width:"100%",gap:"2rem"}}>
        <Autocomplete
          options={optionAOptions}
          renderInput={(params) => <TextField {...params} error={Boolean(optionAValue && optionAValue.allergens.length!==0)} helperText={Boolean(optionAValue && optionAValue.allergens.length!==0) && "Option A cannot have allergens"}  label="Option A" variant="outlined"  style={{textTransform:"capitalize"}}/>}
          value={optionAValue}
          
          onChange={(event, newValue) => {
            // setOptionAValue(newValue);
            newFormFormik.setFieldValue(`menu.${date.format("DD-MMM-YY")}.optionA`,newValue)
          }}
          inputValue={optionAInputValue}
          onInputChange={(event, newInputValue) => {
            setOptionAInputValue(newInputValue);
          }}
          open={optionAOpen}
          onOpen={() => {
            setOptionAOpen(true);
          }}
          onClose={() => {
            setOptionAOpen(false);
          }}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option,value)=>option.docID===value.docID}

          loading={optionALoading}
          fullWidth
          renderOption={(props, option, { inputValue }) => {
            return (
              <li {...props} style={{display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                <Typography variant="body1" style={{textTransform:"capitalize"}}>
                  {option.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {option.description}
                </Typography>
                <div style={{display:"flex"}}>
                {option.allergens.map((allergen)=>{
                  return <Chip color="secondary" label={allergen} key={allergen} style={{margin:"0.25rem",color:"white",fontWeight:"bold"}}/>
                })}
                </div>
                <Divider style={{marginTop:"0.5rem",width:"100%"}} variant="fullWidth"/>
                
              </li>
            );
          }}
        />

        <Autocomplete
          options={optionBOptions}
          renderInput={(params) => <TextField {...params} label="Option B" variant="outlined" />}
          value={optionBValue}
          onChange={(event, newValue) => {
            // setOptionBValue(newValue);
            newFormFormik.setFieldValue(`menu.${date.format("DD-MMM-YY")}.optionB`,newValue)

          }}
          inputValue={optionBInputValue}
          onInputChange={(event, newInputValue) => {
            setOptionBInputValue(newInputValue);
          }}
          open={optionBOpen}
          onOpen={() => {
            setOptionBOpen(true);
          }}
          onClose={() => {
            setOptionBOpen(false);
          }}
          getOptionLabel={(option) => option.name}
          loading={optionBLoading}
          isOptionEqualToValue={(option,value)=>option.docID===value.docID}
          fullWidth
          renderOption={(props, option, { inputValue }) => {
            return (
              <li {...props} style={{display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                <Typography variant="body1" style={{textTransform:"capitalize"}}>
                  {option.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {option.description}
                </Typography>
                <div style={{display:"flex"}}>
                {option.allergens.map((allergen)=>{
                  return <Chip color="secondary" label={allergen} key={allergen} style={{margin:"0.25rem",color:"white",fontWeight:"bold"}}/>
                })}
                </div>
                <Divider style={{marginTop:"0.5rem",width:"100%"}} variant="fullWidth"/>
              </li>
            );
          }}
        />
        </div>
      </div>
    );
}
function PreviousMenu({open,setOpen}:{open:boolean,setOpen:React.Dispatch<React.SetStateAction<boolean>>}) {
  const [indexForm, setIndexForm] = React.useState<formIndex | null>(null);
  React.useEffect(() => {
    getDoc(doc(firestore, "form", "index"))
      .then((doc) => {
        if (!doc.exists()) {
          window.alert("Error fetching index form");
          console.log("Error fetching index form");
          return;
        }
        setIndexForm(doc.data() as formIndex);
      })
      .catch((error) => {
        window.alert("Error fetching index form");
        console.log(error);
      });
  }, []);
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
    >
      <DialogTitle>Previous menu</DialogTitle>
      <DialogContent>{indexForm == null ? <CircularProgress /> : 
      <MenuGrid>{Object.keys(indexForm.menu).sort((dateStringA: string, dateStringB: string) => {
        return dayjs(dateStringA).isBefore(dayjs(dateStringB)) ? -1 : 1;
      }).map((day) => {
        return (
            <div key={day}>
                <Typography variant="h6" gutterBottom style={{gridColumn:"1/2"}}>
                    {day}
                </Typography>
                <MenuSelections options={indexForm.menu[day]}/> 
                <Divider />
            </div>
        )

      })} </MenuGrid>}</DialogContent>
    </Dialog>
  );
}

function MenuSelections({options}:{options:{optionA:Meal,optionB:Meal}}){
    return(
        <div style={{display:"grid",gap:"1rem",gridTemplateColumns:"repeat(2,1fr)"}}>
            <MealCard meal={options.optionA}/>
            <MealCard meal={options.optionB}/>
        </div>
    );
    

}

function MealCard({meal}:{meal:Meal}){
    const [imageURL,setImageURL]=React.useState<string>("");
    React.useEffect(()=>{
        const storageRef=ref(storage,meal.imagePath);
        getDownloadURL(storageRef).then((url)=>{
            setImageURL(url);
        }).catch((error)=>{
            window.alert("Error fetching image");
            console.log(error);
        })
    },[meal]);
    return(
        <div style={{height:"fit-content"}}>

            {imageURL===""?
            <Skeleton variant="rectangular" width="100%" height="20rem" style={{borderRadius:"0.5rem"}}/>
            :
            <img src={imageURL} alt={meal.name} style={{width:"100%",objectFit:"contain",objectPosition:"center",borderRadius:"0.5rem"}}/>}
            <Typography variant="body1" fontWeight="bold">
                {meal.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {meal.description}
            </Typography>
        </div>
    )

}

const MainContainer=styled("div")({
    display:"flex",
    flexDirection:"column",
    justifyContent:"flex-start",
    alignItems:"flex-start",
    width:"100%",
    height:"100%",
    padding:"2rem",
    boxSizing:"border-box"
});

const MenuGrid=styled("div")({ 
    display:"grid",
    gridTemplateColumns:"1fr",
    gap:"1rem"
});