import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../../../assets/logo.png";
import { useAuth } from '../../../contexts/AuthContext';
type NavbarAction={
    name:string;
    onClick:()=>void;
}

export default function Navbar({hideOptions}: {hideOptions?:boolean}) {
  const navigate=useNavigate();
  const AuthContext=useAuth();
  const pages:NavbarAction[]=AuthContext.currentUserInfo?AuthContext.currentUserInfo.role==="parent"?[
    {name:"Home",onClick:()=>{navigate("/Home")}},
    // {name:"Meals",onClick:()=>{navigate("/Meals")}},
    {name:"Pricing",onClick:()=>{navigate("/Pricing")}},
]:[
    {name:"Home",onClick:()=>{navigate("/Admin/")}},
    {name:"Meals",onClick:()=>{navigate("/Admin/Meals")}},
    {name:"Pricing",onClick:()=>{navigate("/Admin/Pricing")}},
    {name:"Users",onClick:()=>{navigate("/Admin/Users")}},
    {name:"Submissions",onClick:()=>{navigate("/Admin/Submissions")}},
    {name:"Food Labels",onClick:()=>{navigate("/Admin/PrintLabels")}},
    {name:"Delivery Labels",onClick:()=>{navigate("/Admin/PrintDelivery")}},

]:[];
const settings:NavbarAction[]=[
    // {name:"Profile",onClick:()=>{navigate("/Profile")}},
    {name:"Logout",onClick:()=>{
        navigate("/SignIn");
        AuthContext.logOut();
    }},
]
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const authContext=useAuth();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="navigation menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {!hideOptions? pages.map((page) => (
                <MenuItem key={page.name} onClick={page.onClick}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              )):null}
            </Menu>
            <img src={logo} style={{height:"5rem",width:"auto",marginRight:"1rem"}} alt="logo"/>

          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <img src={logo} style={{height:"5rem",width:"auto",marginRight:"1rem"}} alt="logo"/>

            {!hideOptions? pages.map((page) => (
              <Button
                key={page.name}
                onClick={page.onClick}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.name}
              </Button>
            )):null}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={authContext.currentUserInfo?.name} src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.name} onClick={setting.onClick}>
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

