import { Dialog, DialogTitle, DialogContent, Avatar, TextField, ButtonGroup, Button, DialogActions, Typography, Select, MenuItem, FormControl, InputLabel, Checkbox, ListItemText, Box, Chip } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { addDoc, collection } from "firebase/firestore";
import { getDownloadURL,ref } from "firebase/storage";
import { FormikProps, useFormik } from "formik";
import React from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { firestore, storage } from "../../../firebase/firebase";
import * as Yup from "yup";
const allergens:string[]=[
    "Milk",
    "Egg",
    "Peanut/Nuts",
    "Fish",
    "Shellfish",
    "Soy",
    "Wheat",
    "Sesame",
    "Molluscs",
  ]

  export function AddChildDialog({open,setOpen}:{open:boolean,setOpen:React.Dispatch<React.SetStateAction<boolean>>}) {
    const authContext = useAuth();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [imageURLS, setImageURLS] = React.useState<string[]>([]);
    const [allergiesSelectOpen, setAllergiesSelectOpen] = React.useState(false);
    const addChildFormik:FormikProps<{
      name: string;
      DOB: dayjs.Dayjs;
      school: string;
      grade: string;  
      gender: number;
      allergies:string[];
    }> = useFormik({
      initialValues: {
        name: "",
        DOB: dayjs(),
        school: "",
        grade: "",
        gender: 0,
        allergies:[] as string[],
      },
      validationSchema: Yup.object({
        name: Yup.string().required("Required").max(50,"Invalid name"),
        DOB: Yup.date().required("Required").max(dayjs().subtract(3,"years").toDate(),"Child must be older than 3 year").min(dayjs().subtract(12,"years").toDate(),"Child must be younger than 12 years"),
        gender: Yup.number().required("Required").min(1,"Required").max(2,"Required"),
        school: Yup.string().required("Required").max(50,"Invalid school"),
        grade: Yup.string().required("Required").max(50,"Invalid grade"),

      }),
      onSubmit:(values) => {
        if(authContext.currentUser===null || authContext.currentUserInfo===null){
            window.alert("No user ID");
            return;
        }
        setLoading(true);
        addDoc(collection(firestore, "users", authContext.currentUser.uid, "children"), {
            ...values,
            allergies:values.allergies.map((allergen)=>allergen.toLowerCase()),
            DOB:values.DOB.set("hour",0).set("minute",0).set("second",0).toDate(),
            activeSubscription:false,
            parentID:authContext.currentUser.uid,
            address:authContext.currentUserInfo.address,
            parentName:authContext.currentUserInfo.name,
            parentPhoneNumber:authContext.currentUserInfo.phoneNumber,
        }).then(()=>{
            setOpen(false);
            addChildFormik.resetForm();
        }).catch((error)=>{
            window.alert("Error adding child");
            console.log(error);
        }).finally(()=>{
            setLoading(false);
        })
      },
    });

    React.useEffect(() => {
      const downloadPictures = async () => {
        const images: Promise<string>[] = ["neutral", "male", "female"].map(async (image) => {
          const url = await getDownloadURL(ref(storage, `avatars/${image}.png`));
          return url;
        });
        return await Promise.all(images);
      };

      downloadPictures().then((urls) => {
        setImageURLS(urls);
        setLoading(false);
      });
    }, []);
    const handleClose = () => {
      setOpen(false);
      addChildFormik.resetForm();
    };

    return (
      <Dialog open={open} onClose={handleClose} component="form" onSubmit={(e)=>{
        e.preventDefault();
        addChildFormik.handleSubmit();
      }} 
      
      fullWidth>
        <DialogTitle>Add child</DialogTitle>
        <DialogContent style={{ display: "flex", flexDirection: "column", gap: "1rem", justifyContent: "safe center"}}>
          <Avatar sx={{ width: "5rem", height: "5rem", margin: "auto" }} src={imageURLS[addChildFormik.values.gender]} />
          <TextField
            variant="outlined"
            label="Name *"
            value={addChildFormik.values.name}
            name="name"
            id="name"
            onChange={addChildFormik.handleChange}
            onBlur={addChildFormik.handleBlur}
            helperText={addChildFormik.touched.name && addChildFormik.errors.name}
            error={addChildFormik.touched.name && Boolean(addChildFormik.errors.name)}
            
            
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Birthday *"
              value={addChildFormik.values.DOB}
              onChange={(newValue) => {
                addChildFormik.setFieldValue("DOB", newValue);
                addChildFormik.setTouched({DOB:true});
              }}
              onClose={() => {
                addChildFormik.handleBlur("DOB");
                addChildFormik.setTouched({DOB:true});
                  }}
              disableFuture
              format="DD-MMM-YYYY"
              slotProps={{ textField:{
                //@ts-ignore
                helperText:addChildFormik.touched.DOB && addChildFormik.errors.DOB,
                error:addChildFormik.touched.DOB && Boolean(addChildFormik.errors.DOB),
              } }}

            />
          </LocalizationProvider>
          {addChildFormik.touched.DOB && <Typography color="text.secondary" fontSize="0.7rem">age:{dayjs().diff(addChildFormik.values.DOB,"years")} YO</Typography>}
          <TextField
            variant="outlined"
            label="School *"
            value={addChildFormik.values.school}
            name="school"
            id="school"
            onChange={addChildFormik.handleChange}
            onBlur={addChildFormik.handleBlur}
            helperText={addChildFormik.touched.school && addChildFormik.errors.school}
            error={addChildFormik.touched.school && Boolean(addChildFormik.errors.school)}
            
          />
            <TextField
            variant="outlined"
            label="Grade *"
            value={addChildFormik.values.grade}
            name="grade"
            id="grade"
            onChange={addChildFormik.handleChange}
            onBlur={addChildFormik.handleBlur}
            helperText={addChildFormik.touched.grade && addChildFormik.errors.grade}
            error={addChildFormik.touched.grade && Boolean(addChildFormik.errors.grade)}
            
          />
          <ButtonGroup  aria-label="outlined primary button group" fullWidth>
            <Button
              onClick={() => {
                addChildFormik.setFieldValue("gender", 1);
              }}
              variant={addChildFormik.values.gender === 1 ? "contained" : "outlined"}
              color={addChildFormik.touched.gender && addChildFormik.errors.gender ? "error" : "secondary"}   
            >
              boy
            </Button>
            <Button
            variant={addChildFormik.values.gender === 2 ? "contained" : "outlined"}
              onClick={() => {
                addChildFormik.setFieldValue("gender", 2);
              }}
              color={addChildFormik.touched.gender && addChildFormik.errors.gender ? "error" : "primary"}
            >
              girl
            </Button>
          </ButtonGroup>
            <FormControl>
        <InputLabel id="allergies-select-label">Allergies</InputLabel>
      <Select
      multiple
      labelId="allergies-select-label"
      label="Allergies"
      open={allergiesSelectOpen}
      onOpen={() => {
        setAllergiesSelectOpen(true);
      }}
      onClose={() => {
        setAllergiesSelectOpen(false);
      }}
      value={addChildFormik.values.allergies}
      onChange={(e)=>{
          addChildFormik.setFieldValue("allergies",e.target.value as string[]);
          setAllergiesSelectOpen(false);
      }}
      // renderValue={(selected) => {
      //   return (selected as string[]).join(', ');
      // }}
      fullWidth
      variant="outlined"
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((value) => (
            <Chip key={value} label={value} />
          ))}
        </Box>
      )}

      >
          {allergens.map((allergen)=>{
              return(             
              <MenuItem key={allergen} value={allergen}>
              <Checkbox checked={addChildFormik.values.allergies.indexOf(allergen) > -1} />
              <ListItemText primary={allergen} />
            </MenuItem>
            )
          })}
      </Select>
      </FormControl>
        </DialogContent>
        <DialogActions >
          <Button onClick={handleClose} disabled={loading} >Cancel</Button>
          <Button type="submit" color="secondary" disabled={loading}>add</Button>
        </DialogActions>
      </Dialog>
    );
  }