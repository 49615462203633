import { Outlet, Navigate, useNavigate, useLocation } from "react-router-dom";
import { AuthContextValue, useAuth } from "../contexts/AuthContext";
import Loading from "../pages/loading/Loading";
export default function ParentRoutes() {
  const authContext:AuthContextValue=useAuth();
  const location=useLocation();
  console.log("location",location);
  if(authContext.userInfoLoading){
    console.log("matched loading");
    return <Loading fullscreen/>
  }
  if(authContext.currentUserInfo===null && !location.pathname.startsWith("/NewUser")){
    console.log("matched newUser 1");
    return <Navigate to="/NewUser"/>
  }
  if((authContext.currentUserInfo===null || !authContext.currentUserInfo.activeAccount) && location.pathname.startsWith("/NewUser")){
    console.log("matched newUser 2");
    return <Outlet/>
  }
  if(authContext.currentUserInfo?.role==="parent" && authContext.currentUserInfo.activeAccount && !location.pathname.startsWith("/NewUser")){
    console.log("matched parent");
    return <Outlet/>
  }
  if(authContext.currentUserInfo?.role==="parent" && !authContext.currentUserInfo.activeAccount){
    console.log("matched newUser 3");
    return <Navigate to="/NewUser"/>
  }
  if(authContext.currentUserInfo?.role==="admin"){
    console.log("matched admin");
    return <Navigate to="/Admin"/>
  }
  console.log("fallback");
  return <Navigate to="/"/>
}