import { Button } from "@mui/material"
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase/firebase";

export default function Meals() {
    const updateIndexForm=()=>{
        console.log("updateIndexForm");
        const docRef=doc(firestore,"form","index");
        const meal1:Meal={
            docID:"EZDbig1JJrnB3n5l75li",
            name:"Peanut Butter & Banana Roll-Ups",
            description:"Whole-grain wraps with a generous spread of peanut butter, rolled up with sliced bananas for a delightful twist",
            ingredients:["whole-grain wraps","peanut butter","bananas"],
            allergens:["peanut"],
            imagePath:"/meals/EZDbig1JJrnB3n5l75li.jpg",
        };
        const meal2:Meal={
            docID:"F95bHWiKRROZHyivGdP7",
            name:"Cheesy Smiles Sandwich",
            description:"Soft whole-grain bread with a layer of creamy cheese spread, featuring playful shapes like smiley faces cut from colorful bell peppers",
            ingredients:["whole-grain bread","cheese spread","colorful bell peppers"],
            allergens:["dairy"],
            imagePath:"/meals/F95bHWiKRROZHyivGdP7.jpg",
        };

        const newMenu:{[day:number]:Meal[]}={
            7:[meal1,meal2],
            8:[meal2,meal1],
            9:[meal1,meal2],
            10:[meal2,meal1],
            11:[meal1,meal2],
        }
        updateDoc(docRef,{menu:newMenu}).then(()=>{
            alert("updated");
        }).catch((error)=>{console.log(error)});
        
    }
    return(
        <div>
            meals
            <Button onClick={updateIndexForm}>update indexForm</Button>
        </div>
    )
}

export type Meal={
    docID:string,
    name:string,
    description:string,
    ingredients:string[],
    allergens:string[],
    imagePath:string,
}

export type Juice={
    docID:string,
    name:string,
    isDefault?:boolean,
    imagePath:string,
}