import { Button, Fab, Typography, styled } from "@mui/material";
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import React from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { firestore, storage } from "../../../firebase/firebase";
import Loading from "../../loading/Loading";
import { Child, formIndex, formSubmission } from "../Home";
import { AddChildDialog } from "./AddChildDialog";
import ChildCard from "./ChildCard";
import AddIcon from '@mui/icons-material/Add';
export default function Children(){
    const [children, setChildren] = React.useState<Child[]>([]);
    console.log("children",children);
    const [loadingChildren, setLoadingChildren] = React.useState<boolean>(true);
    const [loadingSubmissions, setLoadingSubmissions] = React.useState<boolean>(true);
    const [submissions, setSubmissions] = React.useState<{ [child: string]: formSubmission }>({});
    const [indexForm, setIndexForm] = React.useState<formIndex | null>(null);
    const [openAddChildDialog, setOpenAddChildDialog] = React.useState(false);
    const authContext = useAuth();

    React.useEffect(() => {
      //TODO:: when first sign in, children not showing
      if (authContext.currentUser === null) { 
        window.alert("ERR:C_01\nOPs, something went wrong, please contact support");
        console.error("No user ID");
        return;
      }
      const unsubscribe = onSnapshot(collection(firestore, "users", authContext.currentUser.uid, "children"), async (snapshot) => {
        const promises: Promise<Child>[] = snapshot.docs.map(async (doc) => {
          const avatarURL = await getDownloadURL(ref(storage, `avatars/${doc.data().gender === 1 ? "male" : "female"}.png`));
          return { ...doc.data(), avatar: avatarURL, docID: doc.id } as Child;
        });
        const children = await Promise.all(promises);
        setChildren(children);
        setLoadingChildren(false);
      });
      return unsubscribe;
    }, [authContext.currentUser, indexForm]);
    React.useEffect(() => {
      if (authContext.currentUser === null) {
        window.alert("ERR:C_03\nOPs, something went wrong, please contact support");
        console.error("No user ID");
        return;
      }
      const docRef=doc(firestore,"form","index");
      const unsubscribe = onSnapshot(docRef, async (doc) => {
        setLoadingSubmissions(true);
        const data = doc.data() as formIndex;
        setIndexForm(data);
        setLoadingSubmissions(false);
      });
      return unsubscribe; 
    },[authContext.currentUser]);
    React.useEffect(() => {
      if (authContext.currentUser === null) {
        window.alert("ERR:C_04\nOPs, something went wrong, please contact support");
        console.error("No user ID");
        return;
      }
      if(!indexForm){
          return;
      }
      const q = query(collection(firestore, "form"), where("batch", "==", indexForm.batch), where("parentID", "==", authContext.currentUser?.uid));
      const unsubscribe = onSnapshot(q, async (snapshot) => {
        setLoadingSubmissions(true);
        const submissions: { [child: string]: formSubmission } = {};
        snapshot.docs.forEach((doc) => {
          const data = doc.data() as formSubmission;
          submissions[data.childID] = data;
        });
        setSubmissions(submissions);
        setLoadingSubmissions(false);
      });
      return unsubscribe;
    }, [children,indexForm,authContext.currentUser]);
    if(loadingChildren){
        return (<Loading fullscreen={false} opacity={0.5} />)
    }
    return (
      <ChildrenDiv >
            {children.length>0?children.map((child) => {
              return <ChildCard child={child} submission={submissions[child.docID]} loadingSubmissions={loadingSubmissions} key={child.docID} />;
            }):<div style={{display:"flex",flexDirection:"column",gap:"1rem",justifyContent:"center",alignItems:"center",width:"100%"}}>
                <Typography variant="h5" fontWeight="bold">No kids found :(</Typography>
                <Typography variant="h6" color="text.secondary"> Please click the "add child" & start planning meals !</Typography>
                <Button color="primary"  variant="contained" size="large" style={{color:"white"}} onClick={()=>{setOpenAddChildDialog(true)}}>  <AddIcon />Add child</Button>
            </div>}

            {
              children.length<3?
              <>
              <Fab color="primary"  variant="extended" style={{color:"white",marginLeft:"auto",position:"fixed",bottom:"1rem",right:"1rem"}} onClick={()=>{setOpenAddChildDialog(true)}}>  <AddIcon />Add child</Fab>
                <AddChildDialog open={openAddChildDialog} setOpen={setOpenAddChildDialog}/> 
              </>
              :null
            }

      </ChildrenDiv>
    );
  
    
  }



  const ChildrenDiv = styled("div")({
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom:"5rem",
    "@media (min-width: 600px)": {
      flexDirection: "row",
      alignItems: "flex-start",
    }

  });
  