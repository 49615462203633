import { Button, Card, CardActions, CardContent, Chip, Divider, List, ListItem, Typography, styled } from "@mui/material";
import Navbar from "../components/navbar/Navbar";
import { Check, KeyboardArrowRight } from "@mui/icons-material";
import { useParams } from "react-router-dom";

export default function Pricing() {
    return(
<>
<Navbar/>
<MainContainer>
    <CardsDiv>
      <PricingCard planName="Little Ones" benifits={["5 Healthy Meals (Juices Included)","Free Delivery"]} price={15} chip="weekly" period="Week"/>
      <PricingCard planName="Big Ones" benifits={["20 Healthy Meals (Juices Included)","Free Delivery"]} price={55} chip="monthly" period="Month" disabled/>
      </CardsDiv>
</MainContainer>
</>
)


}
function PricingCard({planName,benifits,price,chip,period,disabled}:{planName:string,benifits:string[],price:number,chip:string,period:string,disabled?:boolean}){
    const {childName}=useParams();
    return(
        <PricingCardAnimated  style={{width:"clamp(15rem,100%,35rem)",backgroundColor:disabled?"lightGrey":"",boxShadow:disabled?"none":"inheret"}}>
        <CardContent>
        <Chip  variant="outlined" label={disabled?"Coming Soon":chip.toUpperCase()} />
        <Typography variant="h2" fontSize="clamp(1rem , 10vw, 3.75rem)">{planName}</Typography>
        <Divider  />
        <List sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
          {benifits.map((benifit)=>{
              return(
                  <ListItem style={{display:"flex",alignItems:"flex-start",gap:"0.5rem"}}>
                      <Check style={{color:"grey"}}/>
                      <Typography fontSize="clamp(0.7rem , 10vw, 1rem)"> {benifit}</Typography>
                    
                  </ListItem>
              )
          })}
        </List>
        <Divider  />
        <CardActions>
          <Typography variant="body1" fontSize="clamp(0.8rem , 3vw, 1rem)" fontWeight="bold" sx={{ mr: 'auto',display:"flex" }}>
            BHD {price}{' '}
            <Typography  fontSize="clamp(0.8rem , 3vw, 1rem)" color="text.secondary">
              / {period}
            </Typography>
          </Typography>
          <Button
            variant="contained"
            style={{color:"white",fontSize:"clamp(0.7rem , 3vw, 1rem)"}}
            color="primary"
            size="small"
            endIcon={<KeyboardArrowRight />}
            disabled={disabled}
            onClick={()=>{
                window.open(`https://api.whatsapp.com/send?phone=+97333882311&text=I would like to subscribe to the ${planName} plan${childName ? ` for my child ${childName}`:""}.`,"_blank");

            }}
          >
            Subscribe
          </Button>
        </CardActions>
        </CardContent>
      </PricingCardAnimated>
    )
}

const MainContainer=styled("div")({
    padding:"1rem",
    gap:"1.5rem",
    boxSizing:"border-box",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    height:"calc(100svh - 80px)",
    minHeight:"400px",
    "@media (max-width: 920px)": {
      height:"fit-content",
  },

  });

  const CardsDiv = styled("div")({
    display: "flex",
    width: "75%",
    gap: "5rem",
    // marginTop:"10rem",
    justifyContent: "center",
    "@media (max-width: 1200px)": {
        width:"90%"
    },
    "@media (max-width: 920px)": {
        flexDirection:"column",
        alignItems:"center",
        gap:"2rem",
        marginTop:"0",
    },
  });   

const PricingCardAnimated=styled(Card)({
    transition:"0.5s",
    boxShadow: "0 0 10px 5px #00000036",
    "&:hover":{
        boxShadow:"0px 0px 10px 5px #a2c65436",
        scale:"1.05"
        
    }

});