import { Autocomplete, Button, Card, CardActions, CardContent, Divider, TextField, Typography, styled } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useAuth } from "../../contexts/AuthContext";
import { firestore } from "../../firebase/firebase";
import Navbar from "../components/navbar/Navbar";
import Loading from "../loading/Loading";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// const BAHRAIN_CITIES:{[province:string]:string[]}={
//     "Southern Governorate":[
//         "Aáli",
//         "Al Door",
//         "Al Hajyat",
//         "Al Hunaniya",
//         "Al Jaseera",
//         "Al Omar",
//         "Al Qareen",
//         "Al Rawdha",
//         "Al Riffah",
//         "Al Romaitha",
//         "Al Rumamin",
//         "Al Shabak",
//         "Awali",
//         "Belaj Al Jazair",
//         "Dar Kulaib",
//         "Durrat Al Bahrain",
//         "East Riffa",
//         "Hawrat Sanad",
//         "Hidd Al Jamal",
//         "Hlaitan",
//         "Isa Town",
//         "Jaw",
//         "New districts",
//         "Nuwaydirat",
//         "Ras Abu Jarjor",
//         "Sukhair",
//         "Swayfra",
//         "Trafi",
//         "Um Albaidh",
//         "Um Jadir",
//         "Umm Jidr Al Summan",
//     ],
//     "Northern Governorate":[
//         "Aáli",
//         "Abu Saiba'a",
//         "Al Jasra",
//         "Al Lawzi",
//         "Al Muhamadyia",
//         "Budaiya",
//         "Buri",
//         "Damistan",
//         "Dar Kulaib",
//         "Diraz",
//         "Hamad Town",
//         "Hamala",
//         "Hoarat Aáli",
//         "Jabalt Hibshi",
//         "Jannusan",
//         "Jedah",
//         "Jind Al Haj",
//         "Karzakan",
//         "King Fahad Causway",
//         "Magaba",
//         "Maqsha",
//         "New districts",
//         "Sadaq",
//         "Safriya",
//         "Salmabad",
//     ],
//     "Capital Governorate":[
//         "Abu Al Aish",
//         "Abu Buham",
//         "Adliya",
//         "Al Akr Al Sharqi",
//         "Al Belad Al Qadeem",
//         "Al Hamriya",
//         "Al Kharjiya",
//         "Alnaim",
//         "Al Nasfa",
//         "Alqalla",
//         "Alsalmaniya",
//         "Alseef District",
//         "Alsuwayfia",
//         "Alwajeha Albhariya",
//         "Bu Ghasal",
//         "Bu Ghazal",
//         "Burhama",
//         "Daih",
//         "Diplomatic Area",
//         "Gudaibiya",
//         "Isa Town",
//         "Jidhafs",
//         "Manama Center",
//         "Minaa Salman Industrial Area",
//         "Murgoban",
//         "Nuwaydirat",
//         "Qarya",
//         "Qufool",
//         "Ras Ruman",
//         "Salhiya",
//         "Sanabis",
//     ],
//     "Muharraq Governorate":[
//         "Al Sayh",
//         "Amwaj",
//         "Busaiteen",
//         "Dair",
//         "Galali",
//         "Muharraq",
//     ]
// }
const BAHRAIN_CITIES:{province:string,city:string}[]=[
{province:"Southern",city:"Aáli"},
{province:"Southern",city:"Al Door"},
{province:"Southern",city:"Algainah"},
{province:"Southern",city:"Al Hajyat"},
{province:"Southern",city:"Al Hunaniya"},
{province:"Southern",city:"Al Jaseera"},
{province:"Southern",city:"Al Mamtala"},
{province:"Southern",city:"Al Mazrooeeah"},
{province:"Southern",city:"Al Moaaskar"},
{province:"Southern",city:"Al Omar"},
{province:"Southern",city:"Al Qarah"},
{province:"Southern",city:"Al Qareen"},
{province:"Southern",city:"Al Rawdha"},
{province:"Southern",city:"Al Riffah"},
{province:"Southern",city:"Al Romaitha"},
{province:"Southern",city:"Al Rumamin"},
{province:"Southern",city:"Al Shabak"},
{province:"Southern",city:"Askar"},
{province:"Southern",city:"Awali"},
{province:"Southern",city:"Belaj Al Jazair"},
{province:"Southern",city:"Bu Kuwarah"},
{province:"Southern",city:"Dar Kulaib"},
{province:"Southern",city:"Durrat Al Bahrain"},
{province:"Southern",city:"East Riffa"},
{province:"Southern",city:"Hafeera"},
{province:"Southern",city:"Hawrat Sanad"},
{province:"Southern",city:"Hidd Al Jamal"},
{province:"Southern",city:"Hlaitan"},
{province:"Southern",city:"Horat Anaqa"},
{province:"Southern",city:"Isa Town"},
{province:"Southern",city:"Jari Al Shaikh"},
{province:"Southern",city:"Jaw"},
{province:"Southern",city:"Jazaer Beach"},
{province:"Southern",city:"Juzur Al Dar"},
{province:"Southern",city:"Lhassay"},
{province:"Southern",city:"Maameer"},
{province:"Southern",city:"Mamlahat Al Mamtala"},
{province:"Southern",city:"Mazraa"},
{province:"Southern",city:"New districts"},
{province:"Southern",city:"Nuwaydirat"},
{province:"Southern",city:"Ras Abu Jarjor"},
{province:"Southern",city:"Ras Hayan"},
{province:"Southern",city:"Ras Zuwaid"},
{province:"Southern",city:"Riffa / Albuhair"},
{province:"Southern",city:"Sukhair"},
{province:"Southern",city:"Swayfra"},
{province:"Southern",city:"Trafi"},
{province:"Southern",city:"Um Albaidh"},
{province:"Southern",city:"Um Jadir"},
{province:"Southern",city:"Umm Jidr Al Summan"},
{province:"Southern",city:"Wadi Ali"},
{province:"Southern",city:"Wadi Al Sale"},
{province:"Southern",city:"West Riffa"},
{province:"Southern",city:"Zallaq"},
{province:"Southern",city:"Zayed Town"},
{province:"Northern",city:"Aáli"},
{province:"Northern",city:"Abu Saiba'a"},
{province:"Northern",city:"Al Hajar"},
{province:"Northern",city:"Al Jasra"},
{province:"Northern",city:"Al Lawzi"},
{province:"Northern",city:"Al Muhamadyia"},
{province:"Northern",city:"Al Shakhura"},
{province:"Northern",city:"Bani Jamra"},
{province:"Northern",city:"Barbar"},
{province:"Northern",city:"Budaiya"},
{province:"Northern",city:"Buqwa"},
{province:"Northern",city:"Buri"},
{province:"Northern",city:"Damistan"},
{province:"Northern",city:"Dar Kulaib"},
{province:"Northern",city:"Diraz"},
{province:"Northern",city:"Hamad Town"},
{province:"Northern",city:"Hamala"},
{province:"Northern",city:"Hillat AbdulSaleh"},
{province:"Northern",city:"Hoarat Aáli"},
{province:"Northern",city:"Jabalt Hibshi"},
{province:"Northern",city:"Janabiya"},
{province:"Northern",city:"Jannusan"},
{province:"Northern",city:"Jedah"},
{province:"Northern",city:"Jind Al Haj"},
{province:"Northern",city:"Karana"},
{province:"Northern",city:"Karzakan"},
{province:"Northern",city:"King Fahad Causway"},
{province:"Northern",city:"Magaba"},
{province:"Northern",city:"Malkiya"},
{province:"Northern",city:"Maqsha"},
{province:"Northern",city:"Markh"},
{province:"Northern",city:"New districts"},
{province:"Northern",city:"North City"},
{province:"Northern",city:"Northern City"},
{province:"Northern",city:"Northern Sehla"},
{province:"Northern",city:"Qadam"},
{province:"Northern",city:"Quraya"},
{province:"Northern",city:"Saar"},
{province:"Northern",city:"Sadaq"},
{province:"Northern",city:"Safriya"},
{province:"Northern",city:"Salmabad"},
{province:"Northern",city:"Shahrakan"},
{province:"Northern",city:"Um Al Naasan"},
{province:"Capital",city:"Abu Al Aish"},
{province:"Capital",city:"Abu Buham"},
{province:"Capital",city:"Adhari"},
{province:"Capital",city:"Adliya"},
{province:"Capital",city:"Al Akr Al Sharqi"},
{province:"Capital",city:"Al Belad Al Qadeem"},
{province:"Capital",city:"Alcornish"},
{province:"Capital",city:"Al Fatih"},
{province:"Capital",city:"Al Guraifa"},
{province:"Capital",city:"Al Hamriya"},
{province:"Capital",city:"Al Khamiss"},
{province:"Capital",city:"Al Kharjiya"},
{province:"Capital",city:"Alnaim"},
{province:"Capital",city:"Al Nasfa"},
{province:"Capital",city:"Alqalla"},
{province:"Capital",city:"Alsalmaniya"},
{province:"Capital",city:"Alseef District"},
{province:"Capital",city:"Al Suqayyah"},
{province:"Capital",city:"Alsuwayfia"},
{province:"Capital",city:"Alwajeha Albhariya"},
{province:"Capital",city:"Bu Asheera"},
{province:"Capital",city:"Bu Ghasal"},
{province:"Capital",city:"Bu Ghazal"},
{province:"Capital",city:"Burhama"},
{province:"Capital",city:"Commercial Area"},
{province:"Capital",city:"Daih"},
{province:"Capital",city:"Diplomatic Area"},
{province:"Capital",city:"Gudaibiya"},
{province:"Capital",city:"Hoora"},
{province:"Capital",city:"Isa Town"},
{province:"Capital",city:"Jid Ali"},
{province:"Capital",city:"Jidhafs"},
{province:"Capital",city:"Juffair"},
{province:"Capital",city:"Jurdab"},
{province:"Capital",city:"Karbabad"},
{province:"Capital",city:"Maameer"},
{province:"Capital",city:"Mahaza"},
{province:"Capital",city:"Mahooz"},
{province:"Capital",city:"Manama Center"},
{province:"Capital",city:"Minaa Salman Industrial Area"},
{province:"Capital",city:"Murgoban"},
{province:"Capital",city:"Musala"},
{province:"Capital",city:"Nahib Saleh"},
{province:"Capital",city:"Nurana"},
{province:"Capital",city:"Nuwaydirat"},
{province:"Capital",city:"Qarya"},
{province:"Capital",city:"Qufool"},
{province:"Capital",city:"Ras Ruman"},
{province:"Capital",city:"Salhiya"},
{province:"Capital",city:"Sanabis"},
{province:"Capital",city:"Sanad"},
{province:"Capital",city:"Sitra Industrial Area"},
{province:"Capital",city:"Souq"},
{province:"Capital",city:"Southern Sehla"},
{province:"Capital",city:"Sufala"},
{province:"Capital",city:"Tashan"},
{province:"Capital",city:"Tubli"},
{province:"Capital",city:"Um Albaidh"},
{province:"Capital",city:"Um Alhassam"},
{province:"Capital",city:"Wadyan"},
{province:"Capital",city:"Western Aker"},
{province:"Capital",city:"Zinj"},
{province:"Muharraq",city:"Al Sayh"},
{province:"Muharraq",city:"Amwaj"},
{province:"Muharraq",city:"Arad"},
{province:"Muharraq",city:"Busaiteen"},
{province:"Muharraq",city:"Dair"},
{province:"Muharraq",city:"Galali"},
{province:"Muharraq",city:"Halat Alnaim"},
{province:"Muharraq",city:"Halat Alsulta"},
{province:"Muharraq",city:"Hidd"},
{province:"Muharraq",city:"Muharraq"},
{province:"Muharraq",city:"Samaheej"},
]
export default function NewUser() {
    const authContext=useAuth();
    const [loading,setLoading]=React.useState<boolean>(false);
    const formik=useFormik({
        initialValues:{
            name:"",
            email:"",
            address:{
                city:"",
                block:"",
                road:"",
                building:"",
                flat:""
            },
        },
        validationSchema:Yup.object({
            name:Yup.string().required("Required").max(50,"Invalid name"),
            email:Yup.string().required("Required").email("Invalid email"),
            address:Yup.object({
                city:Yup.string().required("Required").max(50,"Invalid city"),
                block:Yup.number().required("Required").max(9999,"Invalid block").min(0,"Invalid block"),
                road:Yup.string().required("Required").max(50,"Invalid road"),
                building:Yup.string().required("Required").max(50,"Invalid building"),
                flat:Yup.string().max(50,"Invalid flat"),
            })
        }),
        onSubmit:async(values)=>{
            setLoading(true);
            try{
                if(!authContext.currentUser || !authContext.currentUser.uid){
                    throw new Error("No user ID");
                }
                const docRef=doc(firestore,"users",authContext.currentUser.uid);
                if(values.address.flat===""){
                    //@ts-ignore
                    delete values.address.flat;
                }
                await setDoc(docRef,{
                    ...values,
                    activeAccount:true,
                    phoneNumber:authContext.currentUser.phoneNumber,
                    role:"parent",
                },{merge:true});
                setLoading(false);
            }catch(e){
                setLoading(false);
                window.alert("ERR:NU_01\nOps, something went wrong, please try again later.");
                console.error(e);
            }
        },
        })


        return (
          <>
            <Navbar hideOptions={true} />
            <MainContainer>
              <Typography variant="h4" textAlign="center" sx={{ marginBottom: "1rem" }}>
                {" "}
                Welcome to Lunchy!
              </Typography>
              {authContext.userInfoLoading ? (
                <Loading />
              ) : authContext.currentUserInfo && !authContext.currentUserInfo.activeAccount ? (
                <>
                  <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
                    We got you!
                  </Typography>
                  <Typography color="text.secondary" sx={{ marginBottom: "1rem" }}>
                    We are reviewing your information and our lovely team will contact you via call/WhatsApp
                  </Typography>

                  <Card style={{ width: "clamp(15rem,100%,50rem)" }}>
                    <CardContent>
                      <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
                        {" "}
                        Information Provided
                      </Typography>
                      <Divider />
                      <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
                        {" "}
                        Name: {authContext.currentUserInfo?.name}
                      </Typography>
                      <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
                        {" "}
                        Phone number: {authContext.currentUserInfo?.phoneNumber}
                      </Typography>
                      <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
                        {" "}
                        Email: {authContext.currentUserInfo?.email}
                      </Typography>
                      <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
                        {" "}
                        Address: {authContext.currentUserInfo?.address.city},{authContext.currentUserInfo?.address.block},{authContext.currentUserInfo?.address.road},{authContext.currentUserInfo?.address.building},
                        {authContext.currentUserInfo?.address.flat}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Button
                    variant="contained"
                    onClick={() => {
                      window.open("https://api.whatsapp.com/send?phone=+97333882311&text=Hello%20Lunchy%20team,%20I%20have%20a%20question%20about%20my%20account.");
                    }}
                    startIcon={<WhatsAppIcon />}
                    sx={{ marginLeft: "auto", marginRight: "auto", marginTop: "1rem", color: "white" }}
                    color="secondary"
                  >
                    contact us
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
                    {" "}
                    Tell us a little about yourself
                  </Typography>
                  <Card
                    style={{ width: "clamp(15rem,100%,50rem)" }}
                    component="form"
                    noValidate
                    onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                    }}
                  >
                    <CardContent style={{ display: "flex", flexDirection: "column", gap: "1rem", flexWrap: "wrap" }}>
                      <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
                        {" "}
                        Your information
                      </Typography>
                      <Divider />
                      <TextField
                        required
                        variant="outlined"
                        name="name"
                        id="name"
                        label="Name"
                        autoComplete="name"
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                      />
                      <TextField
                        required
                        variant="outlined"
                        name="email"
                        id="email"
                        label="Email"
                        inputMode="email"
                        type="email"
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                      <Typography variant="h6"> Address</Typography>
                      <Divider />
                      <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", justifyContent: "flex-start" }}>
                        {/* <TextField required style={{width:"clamp(10rem,100%,23rem)"}} variant="outlined" name="address.city" id="address.city" label="City" value={formik.values.address.city} onBlur={formik.handleBlur} onChange={formik.handleChange} error={formik.touched.address?.city && Boolean(formik.errors.address?.city)} helperText={formik.touched.address?.city && formik.errors.address?.city}/> */}

                        <Autocomplete
                          style={{ width: "clamp(10rem,100%,23rem)" }}
                          id="address.city"
                          options={BAHRAIN_CITIES.sort((a, b) => -b.province.localeCompare(a.province))}
                          groupBy={(option) => `${option.province.toUpperCase()} GOVERNORATE`}
                          getOptionLabel={(option) => option.city}
                          onChange={(e, value) => {
                            formik.setFieldValue("address.city", value?.city || "");
                          }}
                          sx={{ width: 300 }}
                          onBlur={formik.handleBlur}
                          isOptionEqualToValue={(option, value) => option.city === value.city}
                          noOptionsText="Your city was not found, please select a city close to you"
                          renderInput={(params) => <TextField required style={{ width: "clamp(10rem,100%,23rem)" }}  error={formik.touched.address?.city && Boolean(formik.errors.address?.city)} helperText={formik.touched.address?.city && formik.errors.address?.city} {...params} label="City" />}
                        />

                        <TextField
                          required
                          // style={{  }}
                          // type="number"
                          inputProps={{inputMode:"numeric"}}
                          inputMode="numeric"
                          variant="outlined"
                          name="address.block"
                          id="address.block"
                          label="Block"
                          value={formik.values.address.block}
                          onBlur={formik.handleBlur}
                          onChange={(e)=>{
                            let updatedE=Object.assign({},e);
                            updatedE.target.value=updatedE.target.value.replace(/\D/g,"");
                              if(e.target.value.length<=4){
                                  formik.handleChange(updatedE);
                              }
                            
                          }}
                          error={formik.touched.address?.block && Boolean(formik.errors.address?.block)}
                          helperText={formik.touched.address?.block && formik.errors.address?.block}
                          style={{
                            width: "clamp(10rem,100%,23rem)",
                          }}
                        />
                        <TextField
                          required
                          style={{ width: "clamp(10rem,100%,23rem)" }}
                          variant="outlined"
                          name="address.road"
                          id="address.road"
                          label="Road"
                          value={formik.values.address.road}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={formik.touched.address?.road && Boolean(formik.errors.address?.road)}
                          helperText={formik.touched.address?.road && formik.errors.address?.road}
                        />
                        <TextField
                          required
                          style={{ width: "clamp(10rem,100%,23rem)" }}
                          variant="outlined"
                          name="address.building"
                          id="address.building"
                          label="Building / House"
                          value={formik.values.address.building}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={formik.touched.address?.building && Boolean(formik.errors.address?.building)}
                          helperText={formik.touched.address?.building && formik.errors.address?.building}
                        />
                        <TextField
                          style={{ width: "clamp(10rem,100%,23rem)" }}
                          variant="outlined"
                          name="address.flat"
                          id="address.flat"
                          label="Flat"
                          value={formik.values.address.flat}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={formik.touched.address?.flat && Boolean(formik.errors.address?.flat)}
                          helperText={formik.touched.address?.flat && formik.errors.address?.flat}
                        />
                      </div>
                    </CardContent>
                    <CardActions>
                      <Button variant="contained" type="submit" sx={{ marginLeft: "auto", color: "white" }} disabled={loading}>
                        {" "}
                        Submit
                      </Button>
                    </CardActions>
                  </Card>
                </>
              )}
            </MainContainer>
          </>
        );

}

const MainContainer=styled("div")({
    display:"flex",
    flexDirection:"column",
    justifyContent:"flex-start",
    alignItems:"center",
    width:"100%",
    height:"100%",
    padding:"2rem",
    boxSizing:"border-box",
});