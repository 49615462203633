import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, CircularProgress, styled } from "@mui/material";
import Navbar from "../components/navbar/Navbar";
import { UserInfo } from "../../contexts/AuthContext";
import { collection, doc, getDocs, limit, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { firestore } from "../../firebase/firebase";
import { Child } from "../home/Home";
import dayjs from "dayjs";

function Row({ parent }: { parent: UserInfo }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [children, setChildren] = React.useState<Child[]>([]);
  const [actionsLoading, setActionsLoading] = React.useState<boolean>(false);
  const fetchChildren = React.useCallback(async () => {
    setLoading(true);
    getDocs(query(collection(firestore, "users", parent.docID, "children")))
      .then((snapshot) => {
        const children: Child[] = [];
        snapshot.forEach((doc) => {
          children.push({ ...doc.data(), docID: doc.id } as Child);
        });
        setChildren(children);
      })
      .catch((error) => {
        window.alert("Error fetching children");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  },[parent.docID]);
  React.useEffect(() => {
    if (open) {
        setLoading(true);
      fetchChildren();
    }
  }, [open,fetchChildren]);
  const activate=({parentID,childID}:{parentID:string,childID:string})=>{
    setActionsLoading(true);
    updateDoc(doc(firestore,"users",parentID,"children",childID),{activeSubscription:true}).then(
        ()=>{
            fetchChildren();
        }
    ).catch((error)=>{window.alert("Error updating child status") ;console.error(error)}).finally(()=>{setActionsLoading(false)});
  }
  const deactivate=({parentID,childID}:{parentID:string,childID:string})=>{
    setActionsLoading(true);
    updateDoc(doc(firestore,"users",parentID,"children",childID),{activeSubscription:false}).then(
        ()=>{
            fetchChildren();
        }
    ).catch((error)=>{window.alert("Error updating child status");console.error(error)}).finally(()=>{setActionsLoading(false)});
  }
  const activateParent=({parentID}:{parentID:string})=>{
    setActionsLoading(true);
    updateDoc(doc(firestore,"users",parentID),{activeAccount:true}).then(
        ()=>{
            fetchChildren();
        }
    ).catch((error)=>{window.alert("Error updating parent status");console.error(error)}).finally(()=>{setActionsLoading(false)});
  }

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } ,backgroundColor:!parent.activeAccount?"lightcoral":"white"}}>
        <TableCell>
          {parent.activeAccount && <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>}
        </TableCell>
        <TableCell component="th" scope="row">
          {parent.name}
        </TableCell>
        <TableCell>{parent.phoneNumber}</TableCell>
        <TableCell>{parent.email}</TableCell>
        <TableCell>{`${parent.address.city} , ${parent.address.block}`}</TableCell>
        <TableCell>
            {
                parent.activeAccount?(
                    <Typography fontWeight="bold">
                        Active
                    </Typography>
                ):(
                    <Button color="secondary" variant="contained" style={{color:"white"}} disabled={actionsLoading} onClick={()=>{
                      activateParent({parentID:parent.docID});  
                    }}>
                        activate
                    </Button>
                )
            }
        </TableCell>
      </TableRow>
      <TableRow sx={{ backgroundColor: "lightGrey" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Children
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Age</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>School - Grade</TableCell>
                    <TableCell>Allergies</TableCell>
                    <TableCell>Active Subscription</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    children.map((child) => {
                      return (
                        <TableRow key={child.docID}>
                          <TableCell>{child.name}</TableCell>
                          <TableCell>{dayjs().diff(dayjs(child.DOB.toDate()), "year")}</TableCell>
                          <TableCell>{child.gender === 1 ? "Male" : "Female"}</TableCell>
                          <TableCell>{`${child.school} - ${child.grade}`}</TableCell>
                          <TableCell>{child.allergies.join(", ")}</TableCell>
                          <TableCell>
                            {child.activeSubscription ? (
                              <Typography color="secondary" fontWeight="bold">
                                YES
                              </Typography>
                            ) : (
                              <Typography color="error" fontWeight="bold">
                                NO
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell>
                            {child.activeSubscription ? (
                              <Button color="error" variant="contained" size="small" style={{ width: "50%" }} onClick={()=>{
                                deactivate({parentID:parent.docID,childID:child.docID});

                              }}
                              disabled={actionsLoading}
                              >
                                Deactivate
                              </Button>
                            ) : (
                              <Button color="success" variant="contained" size="small" style={{ width: "50%" }} onClick={()=>{
                                activate({parentID:parent.docID,childID:child.docID});
                              }}
                              disabled={actionsLoading}
                              >
                                Activate
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function AdminUsers() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [users, setUsers] = React.useState<UserInfo[]>([]);
  React.useEffect(() => {
    const q = query(collection(firestore, "users"), where("role", "==", "parent"), limit(50));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const users: UserInfo[] = [];
      snapshot.forEach((doc) => {
        users.push({ ...doc.data(), docID: doc.id } as UserInfo);
      });
      if(users.length>=50){
        window.alert("Only showing first 50 users\nplease contact dev");
      }
      setUsers(users);
      setLoading(false);
    });
    return unsubscribe;
  }, []);
  return (
    <>
      <Navbar />
      <MainContainer>
        <Typography variant="h5" fontWeight="bold">
          Users
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Name</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Address</TableCell>
                    <TableCell>Account Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => {
                  
                    return <Row parent={user} key={user.docID} />;
                  
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </MainContainer>
    </>
  );
}
const MainContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
  height: "100%",
  padding: "2rem",
  boxSizing: "border-box",
});
