import type { ApplicationVerifier, ConfirmationResult, User } from 'firebase/auth';
import { signInWithPhoneNumber, signOut } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import React, { ReactNode } from 'react';
import { auth, firestore } from "../firebase/firebase";

export type AuthContextValue={
    currentUser:User|null;
    authLoading:boolean;
    currentUserInfo:UserInfo|null;
    userInfoLoading:boolean;
    //set return type in send OTP
    sendOTP:({phoneNumber}:{phoneNumber:string})=>Promise<AuthFunctionReturn>;
    verifyOTP:({OTP}:{OTP:string})=>Promise<AuthFunctionReturn>;
    logOut:Function;
}
export type AuthFunctionReturn={
    success:boolean;
    error?:String;

}
export type UserInfo={
    docID:string;
    name:string;
    email:string;
    phoneNumber:string;
    role:string;
    address:{
        city:string;
        block:string;
        road:string;
        building:string;
        flat?:string;
    };
    activeAccount:boolean;
}
const AuthContext=React.createContext<AuthContextValue>({
    currentUser:null,
    currentUserInfo:null,
    userInfoLoading:true,
    authLoading:true,
    sendOTP:({phoneNumber})=>{ console.log("old"); return new Promise(()=>{})},
    verifyOTP:({OTP})=>{console.log("old");return new Promise(()=>{})},
    logOut:()=>{},
});

export function useAuth()
{
    return React.useContext(AuthContext);
}
export function AuthProvider({ children }: { children: ReactNode }): JSX.Element{
    const [currentUser,setCurrentUser]=React.useState<User|null>(null);
    const [currentUserInfo,setCurrentUserInfo]=React.useState<UserInfo|null>(null);
    const [userInfoLoading,setUserInfoLoading]=React.useState<boolean>(true);
    const [authLoading,setAuthLoading]=React.useState<boolean>(true);
    const [userInfoSubscribed,setUserInfoSubscribed]=React.useState<boolean>(false);
    console.log("current User",currentUser);
    console.log("subbed",userInfoSubscribed);
    console.log("current User info",currentUserInfo);
    React.useEffect(()=>{
        const unsubscribe=auth.onAuthStateChanged((user:User|null)=>{   
            setCurrentUser(user);
            setAuthLoading(false);
        })
        return unsubscribe;
    },[])
    React.useEffect(()=>{
        if(currentUser===null){
            setCurrentUserInfo(null);
            setUserInfoLoading(true);
            setUserInfoSubscribed(false);
            return;
        }
        if(!userInfoSubscribed){
        const unsubscribe=onSnapshot(doc(firestore,"users",currentUser.uid),(doc)=>{
            console.log("user info changed",doc.data());
            if(doc.exists()){
                setCurrentUserInfo({...doc.data(),docID:doc.id} as UserInfo);
            }
            setUserInfoLoading(false);
        })
        setUserInfoSubscribed(true);
        return unsubscribe;
    }

    },[currentUser])
    async function sendOTP({phoneNumber}:{phoneNumber:string}):Promise<AuthFunctionReturn>{
        console.log("context send OTP")
        // @ts-ignore
        if(!window.recaptchaVerifier){
            return {
                success:false,
                error:"error loading recaptcha, please refresh the page"
            }
        }
        // @ts-ignore
        const appVerifier:ApplicationVerifier=window.recaptchaVerifier;
        return signInWithPhoneNumber(
            auth,
            `+973 ${phoneNumber}`,
            appVerifier
        ).then(
            (confirmationResult)=>{
                // @ts-ignore
                window.confirmationResult = confirmationResult;
                return {success:true};
            }
        ).catch((e)=>{
            console.log(e);
            return {
                success:false,
                error:"error sending OTP, please try again later or contact support"
            }
        });
    }
    async function verifyOTP({OTP}:{OTP:string}):Promise<AuthFunctionReturn>{
        // @ts-ignore
        if(!window.confirmationResult){
            return {
                success:false,
                error:"error loading confirmation result, please refresh the page or contact support"
            }
        }
        // @ts-ignore
        const confirmationResult:ConfirmationResult=window.confirmationResult;
        return confirmationResult.confirm(OTP).then(
            (res)=>{
                //TODO:: check if useEffect to set auth state is sufficiant
                // setCurrentUser(res.user);
                return {success:true};
            }
        ).catch(()=>{
            return {
                success:false,
                error:"error verifying OTP, please make sure you entered the correct OTP or contact support"
            }
        });
    }
    async function logOut():Promise<AuthFunctionReturn>{
       return signOut(auth).then(
              ()=>{
                window.location.reload();
                return {success:true};
              }
       )
       .catch(()=>{
            return {
            success:false,
            error:"Logout failed, please try again later",
            }
        })
    }
    const value: AuthContextValue={
        currentUser,
        currentUserInfo,
        userInfoLoading,
        authLoading,
        sendOTP,
        verifyOTP,
        logOut,
    }
    return (
        <AuthContext.Provider value={value}>
            {authLoading ? <></> : children}
        </AuthContext.Provider>
    )
}