import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { InputAdornment } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { useFormik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import signInImg from "../../assets/signin-min.jpg";
import { useAuth } from '../../contexts/AuthContext';


function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://lunchyapp.com/">
      lunchyapp
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignIn() {

  const [step, setStep] = React.useState(0);
  const phoneNumberFormik=useFormik({
    initialValues:{
      phoneNumber:""
    },
    onSubmit:()=>{
        console.log("formik submitted");
        // @ts-ignore
        window.recaptchaVerifier.verify();

    },
    validationSchema:Yup.object({
        phoneNumber:Yup.string().required("Required").matches(/^[0-9]{8}$/,{message:"Invalid phone number"})
    }),
  })
  const OTPFormik=useFormik({
    initialValues:{
      OTP:""
    },
    onSubmit:()=>{
        console.log("OTP formik submitted");
        localVerifyOTP();
    },
    validationSchema:Yup.object({
        OTP:Yup.string().required("Required").matches(/^[0-9]{6}$/,{message:"Invalid OTP"})
    }),
    onReset:()=>{
        OTPFormik.setFieldValue("OTP","");
    }
  });
  const [loading, setLoading] = React.useState(false);
  const [allowSendOTP, setAllowSendOTP] = React.useState(false);
  const auth = getAuth();
  const authContext = useAuth();
//   console.log("phone number",phoneNumberFormik.values.phoneNumber);
//   console.log("current user",authContext.currentUser);
  auth.languageCode = "en";

  React.useEffect(() => {
    //TODO:: invistegate if there is still a timeout bug that happens when you send the OTP but then do not verify it (timout issue)
    // @ts-ignore
    if (!window.recaptchaVerifier) {
        console.log("initializing recaptcha")
      // @ts-ignore
      window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
        callback: (response: any) => {  
            console.log("recaptcha verified");
            setAllowSendOTP(true);
        },
        "expired-callback": () => {},
      });
      //@ts-ignore
      window.recaptchaVerifier.render();

    }
  }, []);
  const localSendOTP = React.useCallback( () => {
    // console.log("here");
    // setLoading(true);
    // console.log("PN",phoneNumberFormik.values.phoneNumber);
    // console.log(authContext);
    if(!allowSendOTP){
        return;
    }
    authContext
      .sendOTP({ phoneNumber: phoneNumberFormik.values.phoneNumber})
      .then((result) => {
        console.log(result);
        if (result.success) {
          console.log("OTP sent");
            setStep(1);
        } else {
          window.alert(result.error);
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error(error);
        window.alert("Error sending OTP");
      }).finally(()=>{
        setLoading(false);
      });
  }, [phoneNumberFormik.values.phoneNumber,allowSendOTP]);

  React.useEffect(() => {
    if (allowSendOTP) {
      localSendOTP();
    }
  }, [allowSendOTP]);
  
  const localVerifyOTP = () => {
    authContext
      .verifyOTP({ OTP: OTPFormik.values.OTP })
      .then((result) => {
        if (result.success) {
            console.log(authContext.currentUser);
          console.log("OTP verified");
        } else {
          alert(result.error);
        }
      })
      .catch((error) => {
        //TODO:: fix error msg
        alert(error);
      });
  }
  const editPhoneNumber = () => { 
    setStep(0);
    setAllowSendOTP(false);
    OTPFormik.resetForm();
  };

  return (
    <Grid container component="main" sx={{ height: "100svh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${signInImg})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main"}}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
            {loading? "loading": ""}
          </Typography>

          {step === 0 ? (
            <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={(e)=>{
                e.preventDefault();
                phoneNumberFormik.handleSubmit();
            }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                name="phoneNumber"
                autoComplete="tel-country-code"
                type='tel'
                inputMode='tel'
                autoFocus
                
                InputProps={{
                  startAdornment: <InputAdornment position="start">+973</InputAdornment>,
                  inputMode:"numeric"
                }}
                value={phoneNumberFormik.values.phoneNumber}
                onChange={(e)=>{
                    if(e.target.value.length<=8){
                        phoneNumberFormik.handleChange(e);
                    }
                }}
                onBlur={phoneNumberFormik.handleBlur}
                error={phoneNumberFormik.touched.phoneNumber && Boolean(phoneNumberFormik.errors.phoneNumber)}
                helperText={phoneNumberFormik.touched.phoneNumber && phoneNumberFormik.errors.phoneNumber}
              />
              <Button
                fullWidth
                variant="contained"
                type='submit'
                sx={{ mt: 3, mb: 2 }}   
              >
                Send OTP
              </Button>
              
              <Copyright sx={{ mt: 5 }} />
              <Typography variant='body2' style={{position:"absolute",bottom:"1rem",right:"1rem"}} fontWeight="bold" color="text.secondary" fontSize="0.7rem">V ALPHA 1.0.1</Typography>


            </Box>
          ) : (
            <Box component="form" noValidate onSubmit={(e)=>{
                e.preventDefault();
                OTPFormik.handleSubmit();
            
            }}  sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="OTP"
                label="OTP"
                name="OTP"
                autoComplete="one-time-code"
                autoFocus
                value={OTPFormik.values.OTP}
                onChange={(e) => {
                  if(e.target.value.length<=6){
                    OTPFormik.handleChange(e);
                }
                  
                }}
                inputProps={{
                  inputMode:"numeric"
                }}
                onBlur={OTPFormik.handleBlur}
                error={OTPFormik.touched.OTP && Boolean(OTPFormik.errors.OTP)}
                helperText={OTPFormik.touched.OTP && OTPFormik.errors.OTP}
              />
              <Button
                fullWidth
                variant="contained"
                type='submit'
                sx={{ mt: 3, mb: 2 }}
              >
                verify OTP
              </Button>
              <Grid container>
                <Grid item>
                  <Typography variant="body2" onClick={()=>{
                        editPhoneNumber();
                  }} sx={{cursor:"pointer"}}>
                      {`OTP sent to +973 ${phoneNumberFormik.values.phoneNumber}`}</Typography>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />

            </Box>
          )}
            <div id="recaptcha-container"></div>
        </Box>
      </Grid>

    </Grid>
  );
}