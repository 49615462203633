import React from 'react'
import { Button, Card, CardContent, Divider, Fab, FormControl, InputLabel, MenuItem, Select, Skeleton, TextField, Typography, styled } from "@mui/material";
import Navbar from "../components/navbar/Navbar";
import { formIndex, formSubmission } from '../home/Home';
import { query, collection, where, limit, onSnapshot, doc, getDoc, orderBy } from 'firebase/firestore';
import { firestore } from '../../firebase/firebase';
import dayjs from 'dayjs';
import PrintIcon from '@mui/icons-material/Print';

import { Options, Resolution, usePDF } from 'react-to-pdf';
const pdfOptions:Options={
    resolution:Resolution.HIGH,
    canvas:{
      qualityRatio:2,
    }
  }
export default function AdminPrintDeliveryLabels() {
    const [batch,setBatch]=React.useState<number>(-1);
    const [loading,setLoading]=React.useState<boolean>(true);
    const [indexForm,setIndexForm] =React.useState<formIndex>();
    const [selectedDate,setSelectedDate]=React.useState<string>("");
    const [submissions,setSubmissions]=React.useState<formSubmission[]>([]);
    const { toPDF, targetRef } = usePDF({filename: 'Delivery Labels.pdf'});
    React.useEffect(()=>{
        const fetchData=async()=>{
            try{
                const indexFormRef=doc(firestore,"form","index");
                const indexFormDoc=await getDoc(indexFormRef);
                const indexFormData=indexFormDoc.data() as formIndex;
                setIndexForm(indexFormData);
                setBatch(indexFormData.batch);
                setLoading(false);
            }catch(error){
                window.alert("Error fetching data 2");
            }
        };
        fetchData();
    },[]);
    React.useEffect(() => { 
        if(batch===-1){
            return;
        }
        const q = query(collection(firestore, "form"), where("batch", "==", batch),orderBy("parentID"),limit(150));
        setLoading(true);
        const unsubscribe = onSnapshot(q, async (snapshot) => {
          const promises: formSubmission[] = [];
          snapshot.docs.forEach((doc) => {
            if (doc.id !== "index") {
              promises.push(doc.data() as formSubmission);
            }
          });
          const submissions = await Promise.all(promises);
          if(submissions.length>140){
            window.alert("only showing 150 submissions\nplease contact dev team")
          }
          setSubmissions(submissions);
          setLoading(false);
        });
        return () => {
          console.log("unsubscribing");
          unsubscribe();
        };
        }, [batch]);
    return (
      <>
        <Navbar />
        <MainContainer>
          {loading ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          ) : (
            <div style={{ display: "flex", gap: "0.5rem", width: "35%" }}>
              <FormControl fullWidth>
                <InputLabel id="dateSelect">Select Date</InputLabel>
                <Select labelId="dateSelect" label="Select Date" onChange={(e)=>{
                    setSelectedDate(e.target.value as string);
                }}
                value={selectedDate}>
                  {indexForm &&
                    Object.keys(indexForm.menu)
                      .sort((dateStringA: string, dateStringB: string) => {
                        return dayjs(dateStringA).isBefore(dayjs(dateStringB)) ? -1 : 1;
                      })
                      .map((date) => {
                        return <MenuItem key={date} value={date}>{date}</MenuItem>;
                      })}
                </Select>
              </FormControl>
            </div>
          )}
          <div ref={targetRef} style={{display:"flex",flexDirection:"row",gap:"5rem",padding:"1rem",paddingTop:"5rem",paddingBottom:"3rem",flexWrap:"wrap",height:"100%",justifyContent:"center",alignItems:"center",width:"1423.200px"} }>
          {
           selectedDate && submissions.map((submission)=>{
                return <Label submission={submission} selectedDate={selectedDate} key={`${submission.childID}-${submission.batch}`}/>;
            })
            
          }
          </div>
          <Fab variant='extended' onClick={()=>{toPDF(pdfOptions)}}  color='primary' style={{position:"fixed",bottom:"2rem",right:"2rem",color:"white",display:"flex",gap:"0.2rem"}}><PrintIcon style={{color:"white"}}/> print PDF</Fab>

        </MainContainer>
      </>
    );
}

function Label({submission,selectedDate}:{submission:formSubmission,selectedDate:string}){

    return (
      <LabelCard>
        <CardContent>
          <Typography variant="h5" textAlign="center" textTransform="uppercase" fontWeight="bold" fontSize="2.5rem">
            {submission.childName}
          </Typography>
          <Divider variant="fullWidth" style={{marginBottom:"1rem"}}  />
          <Typography variant="h6" textAlign="center"  fontWeight="bold" fontSize="1.8rem" textTransform="capitalize">
            {submission.selection[selectedDate].meal.name}
          </Typography>
          <Typography variant="h6" textAlign="center"  fontWeight="bold" fontSize="1.8rem"  textTransform="capitalize" style={{marginBottom:"1rem"}}>
            {submission.selection[selectedDate].juice.name} juice
          </Typography>

          <Divider variant="fullWidth" style={{ borderWidth: "0.1rem", borderRadius: "1rem", margin: "0.5rem 0 0.5rem 0" }} />
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <div style={{ display: "flex", flexDirection: "column",width:"100%" }}>
              <Typography variant="body1" fontWeight="bold" style={{display:"flex",alignItems:"flex-end",gap:"0.5rem"}} fontSize="1.5rem">
                Address
                <Typography variant='body1'  fontSize="1.5rem">
                    {
                        `${submission.address.flat ? "F: "+submission.address.flat+", ":""}BLDG: ${submission.address.building}, R: ${submission.address.road}, B: ${submission.address.block}, ${submission.address.city}`
                    }
                </Typography>
                
              </Typography>

              <Divider variant="fullWidth" style={{ borderWidth: "0.1rem", borderRadius: "1rem", margin: "0.5rem 0 0.5rem 0" }} />


              <Typography variant="body1" fontWeight="bold" style={{display:"flex",alignItems:"flex-end",gap:"0.5rem"}} fontSize="1.5rem">
                Parent Contact
                <Typography variant='body1' fontSize="1.5rem">
                    {
                        `${submission.parentName}, ${submission.parentPhone}`
                    }
                </Typography>
              </Typography>
              <Divider variant="fullWidth" style={{ borderWidth: "0.1rem", borderRadius: "1rem", margin: "0.5rem 0 0.5rem 0" }} />

            </div>
          </div>
          <Typography variant="body2" fontSize="1rem" style={{ textTransform: "capitalize" }}>
            *Our food may contain or have come in contact with wheat, soy, eggs or dairy products
          </Typography>
        </CardContent>
      </LabelCard>
    );
};

const MainContainer=styled("div")({
    display:"flex",
    flexDirection:"column",
    justifyContent:"flex-start",
    alignItems:"flex-start",
    width:"100%",
    height:"100%",
    padding:"2rem",
    boxSizing:"border-box",
});

 
const LabelCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  width: "620px",
  height: "400px",
  scale: "1.1",
  border: "1px solid black",
  borderRadius: "0",
  ":nth-child(8n+8)": {
    marginBottom: "8.33rem",
  },
  ":nth-child(8n+7)": {
    marginBottom: "8.33rem",
  }
});