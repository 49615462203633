import { Outlet, Navigate } from "react-router-dom";
import { AuthContextValue, useAuth } from "../contexts/AuthContext";
import Loading from "../pages/loading/Loading";
export default function UnauthenticatedRoutes() {
  const authContext:AuthContextValue=useAuth();
  return (
    <>
    {
      authContext.authLoading ? <Loading /> :
      authContext.currentUser ?  <Navigate to="/Home" /> : <Outlet />
    }
    </>
    ) ;
}
