import { Card, CardMedia, CardContent, Typography, CardActions, Button, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Child, formSubmission } from "../Home";
import dayjs from "dayjs";
import React, { ReactComponentElement } from "react";

export default function ChildCard({ child, submission,loadingSubmissions }: { child: Child; submission: formSubmission | undefined,loadingSubmissions:boolean }) {
    const navigate = useNavigate();
    console.log(submission);
    console.log(new Date().getDate());
    const upcomingMealDate:null | undefined | string=React.useMemo(()=>{
        if(!submission){
            return null;
        }
        const upcomingMealDate=Object.keys(submission.selection).sort((dateA,DateB)=>{
            return dayjs(dateA).isBefore(dayjs(DateB)) ? -1 : 1;
          }).find((date)=>{
            return dayjs(date).isAfter(dayjs()) && submission.selection[date]!==null;
          });
        return upcomingMealDate;
    },[submission]);
    const currentChildAction=React.useMemo(()=>{
      if(loadingSubmissions){
        return <Skeleton variant="text" style={{width:"70%"}}/>;
      }
      if(submission){
        if(submission.submittedAt || dayjs(submission.weekStart.toDate()).diff(dayjs(),"hours") as number < 24){
          return upcomingMealDate ? <div style={{display:"flex",flexDirection:"column"}}><Typography variant="body2" fontWeight="bold" textTransform="capitalize">upcoming meal:</Typography> <Typography variant="body2" color="text.secondary" textTransform="capitalize">{submission.selection[upcomingMealDate].meal.name} & {submission.selection[upcomingMealDate].juice.name} juice</Typography> <Typography variant="body2" fontWeight="bold" textTransform="capitalize">next delivery date: </Typography> <Typography variant="body2" color="text.secondary" textTransform="capitalize">{dayjs(upcomingMealDate).subtract(1,"day").format("DD-MM-YY")} </Typography></div> :<Typography variant="body2" color="text.secondary">Menu yet to be set for this week</Typography>;
        }
        return <Button variant="contained" style={{color:"white",marginLeft:"auto"}} onClick={()=>{navigate(`/MealSelection/${child.docID}`)}}>Select meals</Button>;
      }
      if(child.activeSubscription){
        return <Typography variant="body2" color="success" fontWeight="bold"> Subscribed for next week</Typography>;
      }
      return <>  <Typography color="error" fontWeight="bold" >unsubscribed</Typography> <Button variant="contained" style={{color:"white",marginLeft:"auto"}} onClick={()=>{navigate(`/Pricing/${child.name}`)}}>Subscribe now</Button> </>;
    },[submission,loadingSubmissions,child,upcomingMealDate]);
    return (
      <Card style={{width:"clamp(8rem,100%,25rem)"}}>
        <CardMedia component="img" alt="avatar" style={{ objectFit: "contain", backgroundColor: child.gender === 1 ? "#A2C654" : "#F97A7A" }} height="140" image={child.avatar} />
        <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {child.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
            {child.grade} grade - {child.school} School
        </Typography>
        </CardContent>
        <CardActions style={{display:"flex",flexDirection:"column",alignItems:"flex-start",gap:"1rem"}}>
        {currentChildAction}
        {submission && !child.activeSubscription && <Typography variant="body2" color="error" style={{margin:0}}>Please renew your subscription soon!</Typography> }
        </CardActions>
      </Card>
    );
  }