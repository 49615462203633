import { ThemeProvider, createTheme } from '@mui/material';
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import './App.css';
import { AuthProvider } from './contexts/AuthContext';
import MealsEditor from './pages/MealsEditor/MealsEditor';
import AdminHome from './pages/adminHome/AdminHome';
import AdminNewForm from './pages/adminNewForm/AdminNewForm';
import AdminSubmissions from './pages/adminSubmissions/AdminSubmissions';
import AdminUsers from './pages/adminUsers/AdminUsers';
import Form from './pages/form/Form';
import Home from './pages/home/Home';
import Meals from './pages/meals/Meals';
import NewUser from './pages/newUser/NewUser';
import NotFound from './pages/notFound/NotFound';
import SignIn from './pages/signIn/SignIn';
import AdminRoutes from './routes/AdminRoutes';
import ParentRoutes from './routes/ParentRoutes';
import PrivateRoutes from './routes/PrivateRoutes';
import UnauthenticatedRoutes from './routes/UnauthenticatedRoutes';
import AdminPrintLabels from './pages/adminPrintLabels/AdminPrintLabels';
import AdminPrintDeliveryLabels from './pages/adminPrintDeliveryLabels/AdminPrintDeliveryLabels';
import Pricing from './pages/Pricing/Pricing';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#ed9a48",
      },
      secondary: {
        main: "#A2C654",
      },
    },
  });
  if(process.env.REACT_APP_ENV==="prod"){
    console.log=()=>{};
  }
  return (
    //TODO:: when user first logs in he is not directed to the home page

    <ThemeProvider theme={theme}>
      <Router>
        <AuthProvider>
          <Routes>
            <Route element={<UnauthenticatedRoutes />}>
              <Route path="/SignIn" element={<SignIn />} />
            </Route>
            <Route element={<PrivateRoutes />}>
              <Route element={<ParentRoutes />}>
                <Route path="/" element={<Navigate to="/Home" replace={true} />} />
                <Route path="/Home" element={<Home />} />
                <Route path="/MealSelection/:childID" element={<Form />} />
                <Route path="/Meals" element={<Meals />} />
                <Route path="/NewUser" element={<NewUser/>}/>
                <Route path="/Pricing/:childName?" element={<Pricing/>}/>
                <Route path="*" element={<NotFound />} />
                
              </Route>
              <Route path="/Admin">
                <Route element={<AdminRoutes />}>
                  <Route path="" element={<AdminHome />} />
                  <Route path="Meals" element={<MealsEditor/>} />
                  <Route path="newForm" element={<AdminNewForm/>}/>
                  <Route path="Users" element={<AdminUsers/>}/>
                  <Route path="Submissions" element={<AdminSubmissions/>} />
                  <Route path="PrintLabels" element={<AdminPrintLabels/>} />
                  <Route path="PrintDelivery" element={<AdminPrintDeliveryLabels/>} />
                  <Route path="*" element={<NotFound />} />
                </Route>
              </Route>
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
