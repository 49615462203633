import { Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled } from "@mui/material";
import Navbar from "../components/navbar/Navbar";
import React from "react";
import { getDoc, doc, query, where, collection, onSnapshot, limit, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase/firebase";
import { formIndex, formSubmission } from "../home/Home";
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import dayjs from "dayjs";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
export default function AdminSubmissions() {
    const [loadingBatch,setLoadingBatch]=React.useState<boolean>(true);
    const [loadingSubmissions,setLoadingSubmissions]=React.useState<boolean>(true);
    const [submissions,setSubmissions]=React.useState<formSubmission[]>([]);
    const [openKitchenReportDialog,setOpenKitchenReportDialog]=React.useState(false);
    const [batch,setBatch]=React.useState<number>(-1);
    const [batchInputValue,setBatchInputValue]=React.useState<number>(-1);
    console.log("submissions",submissions)
    React.useEffect(()=>{
        const fetchData=async()=>{
            try{
                const indexFormRef=doc(firestore,"form","index");
                const indexFormDoc=await getDoc(indexFormRef);
                const indexFormData=indexFormDoc.data() as formIndex;
                setBatch(indexFormData.batch);
                setBatchInputValue(indexFormData.batch);
                setLoadingBatch(false);
            }catch(error){
                window.alert("Error fetching data 4");
            }
        };
        fetchData();
    },[]);
    React.useEffect(() => { 
    if(batch===-1){
        return;
    }
    const q = query(collection(firestore, "form"), where("batch", "==", batch),limit(150));
    setLoadingSubmissions(true);
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const promises: formSubmission[] = [];
      snapshot.docs.forEach((doc) => {
        if (doc.id !== "index") {
          promises.push(doc.data() as formSubmission);
        }
      });
      const submissions = await Promise.all(promises);
      if(submissions.length>140){
        window.alert("only showing 150 submissions\nplease contact dev team")
      }
      setSubmissions(submissions);
      setLoadingSubmissions(false);
    });
    return () => {
      console.log("unsubscribing");
      unsubscribe();
    };
    }, [batch]);
    return (
      <>
        <Navbar />
        <MainContainer>
          <div style={{ display: "flex" }}>
            <TextField
              label="Batch"
              value={batchInputValue}
              onChange={(e) => {
                if(e.target.value!==""){
                setBatchInputValue(parseInt(e.target.value));
            }
              }}
              disabled={loadingBatch}
              type="number"
            />
            <Button onClick={()=>{
              // setLoadingSubmissions(true);
              setBatch(batchInputValue);
            }}>Go</Button>
          </div>
          <Typography variant="h5" fontWeight="bold">
            Submissions
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell/>
                  <TableCell>Child Name</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loadingSubmissions?submissions.map((submission) => {

                  return <SubmissionRow submission={submission}/>
                }):
                [1,2,3].map((index)=>{
                  return(
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="circular" width={25} height={25}/>
                  </TableCell>
                        <TableCell><Skeleton variant="text" width={150}/></TableCell>
                      <TableCell><Skeleton variant="text" width={150}/></TableCell>
                      <TableCell><Skeleton variant="text" width={150}/></TableCell>
                      <TableCell></TableCell>
                </TableRow>)}
                )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <Fab
            variant="extended"
            onClick={() => {
              setOpenKitchenReportDialog(true);
            }}
            style={{ position: "fixed", bottom: "1rem", right: "1rem" }}
            color="primary"
          >
            <RestaurantMenuIcon />
            Generate kitchen report
          </Fab>
          <KitchenReportDialog open={openKitchenReportDialog} setOpen={setOpenKitchenReportDialog} batch={batch}/>
        </MainContainer>
      </>
    );
}

function SubmissionRow({submission}:{submission:formSubmission}){
  const [open,setOpen]=React.useState(false);
  return(
    <>
  <TableRow sx={{backgroundColor:!submission.submittedAt?"lightcoral":"" }}>
  <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
    <TableCell>{submission.childName}</TableCell>
    <TableCell>{submission.parentPhone}</TableCell>
    <TableCell>{`${submission.address.city},${submission.address.block}`}</TableCell>
  </TableRow>
  <TableRow sx={{ backgroundColor: "lightGrey" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Selection
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Meal</TableCell>
                    <TableCell>Ingredients</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    Object.keys(submission.selection)
                    .sort((dateStringA: string, dateStringB: string) => {
                      return dayjs(dateStringA).isBefore(dayjs(dateStringB)) ? -1 : 1;
                    }).map((date)=>{
                      return (
                        <TableRow key={date}>
                          <TableCell>{dayjs(date).format("DD-MMM-YY")}</TableCell>
                          <TableCell>{submission.selection[date].meal.name}</TableCell>
                          <TableCell>{submission.selection[date].meal.ingredients.join(", ")}</TableCell>
                          </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
  </>
  )
}

function KitchenReportDialog({open,setOpen,batch}:{open:boolean,setOpen:(open:boolean)=>void,batch:number}){
    const [loading,setLoading]=React.useState<boolean>(true);
    const [mealData,setMealData]=React.useState<{[date:string]:{[docID:string]:{mealName:string,qty:number}}} | null>(null);
    const [juiceData,setJuiceData]=React.useState< {[date:string]:{[docID:string]:{juiceName:string,qty:number}}}|null >(null);
    const generateCSV=()=>{
        if(mealData===null || juiceData===null){
            return;
        }
        // const csvContent = "data:text/csv;charset=utf-8," + Object.keys(mealData).sort((dateStringA: string, dateStringB: string) => {
        //     return dayjs(dateStringA).isBefore(dayjs(dateStringB)) ? -1 : 1;
        //   }).map((date)=>{
        //     return Object.keys(mealData[date]).map((docID)=>{
        //         return `${date},${mealData[date][docID].mealName},${mealData[date][docID].qty}`;
        //     }).join("\n");
        // }).join("\n");
        let csvContent="data:text/csv;charset=utf-8, ";
        Object.keys(mealData).sort((dateStringA: string, dateStringB: string) => {
              return dayjs(dateStringA).isBefore(dayjs(dateStringB)) ? -1 : 1;
            }).forEach((date)=>{
                csvContent+=date+"\n";
                Object.keys(mealData[date]).forEach((mealDocID)=>{
                  csvContent+=`,MEAL: ${mealData[date][mealDocID].mealName},${mealData[date][mealDocID].qty}\n`
                })
                Object.keys(juiceData[date]).forEach((juiceDocID)=>{
                  csvContent+=`,JUICE: ${juiceData[date][juiceDocID].juiceName},${juiceData[date][juiceDocID].qty}\n`
                })
                csvContent+='\n';
          })  
              const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `kitchen_report_${batch}.csv`);
        document.body.appendChild(link); // Required for FF
        link.click();
    };
    React.useEffect(()=>{
        if(open){
            setLoading(true);
            const q=query(collection(firestore,"form"),where("batch","==",batch));
            getDocs(q).then((snapshot)=>{
                const localMealData:{[date:string]:{[docID:string]:{mealName:string,qty:number}}}={};
                const localJuiceData:{[date:string]:{[docID:string]:{juiceName:string,qty:number}}}={};
                
                snapshot.docs.forEach((doc)=>{
                    if(doc.id==="index"){
                        return;
                    }
                    const submission=doc.data() as formSubmission;
                    Object.keys(submission.selection).forEach((date)=>{
                        console.log(submission.selection[date]);
                        if(submission.selection[date]!==null){
                            if(localMealData[date]===undefined){
                              localMealData[date]={};
                            }
                            if(localMealData[date][submission.selection[date].meal.docID]===undefined){
                              localMealData[date][submission.selection[date].meal.docID]={mealName:submission.selection[date].meal.name,qty:1};
                            }else{
                              localMealData[date][submission.selection[date].meal.docID].qty+=1;
                            }

                            if(localJuiceData[date]===undefined){
                              localJuiceData[date]={};
                            }
                            if(localJuiceData[date][submission.selection[date].juice.docID]===undefined){
                              localJuiceData[date][submission.selection[date].juice.docID]={juiceName:submission.selection[date].juice.name,qty:1};
                            }else{
                              localJuiceData[date][submission.selection[date].juice.docID].qty+=1;
                            }
                        }
                    })
                });
                setMealData(localMealData);
                setJuiceData(localJuiceData);

            }).catch((error)=>{window.alert("Error fetching data 5"); console.error(error)}).finally(()=>{setLoading(false)});
        }
    },[open]);
    return (
      <Dialog
        open={open}
        onClose={()=>{setOpen(false)}}
        fullWidth
      >  
      <DialogTitle>
        <Typography variant="h5" fontWeight="bold">
          Kitchen report
        </Typography>
        </DialogTitle>
        <Divider/>
        <DialogContent style={{display:"flex",gap:"1rem",flexDirection:"column"}}>
            {mealData && Object.keys(mealData).sort((dateStringA: string, dateStringB: string) => {
                return dayjs(dateStringA).isBefore(dayjs(dateStringB)) ? -1 : 1;
              }).map(
                (date)=>{
                    return (
                        <div key={date}>
                          <Typography variant="h6" fontWeight="bold">
                            {dayjs(date).format("DD/MM/YYYY")}
                          </Typography>
                          <TableContainer component={Paper}>
                            <Table>
                              {/* <TableHead>
                                <TableRow>
                                  <TableCell align="left">Meal</TableCell>
                                  <TableCell align="right">Quantity</TableCell>
                                </TableRow>
                              </TableHead> */}
                              <TableBody>
                                {Object.keys(mealData[date]).map((docID) => {
                                  return (
                                    <TableRow key={docID}>
                                      <TableCell align="left">{mealData[date][docID].mealName}</TableCell>
                                      <TableCell align="right">{mealData[date][docID].qty}</TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      );
                }
              )}
        </DialogContent>
        <DialogActions>
            <Button startIcon={<FileDownloadIcon/>} onClick={()=>{
              generateCSV();  
            }} >download</Button>
        </DialogActions>
      </Dialog>
    );
}
const MainContainer=styled("div")({
    display:"flex",
    flexDirection:"column",
    justifyContent:"flex-start",
    alignItems:"flex-start",
    width:"100%",
    height:"100%",
    padding:"2rem",
    boxSizing:"border-box",
});
