import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, IconButton, IconButtonProps, Skeleton, TextField, Typography, styled } from "@mui/material";
import { addDoc, collection, deleteDoc, doc, limit, onSnapshot, orderBy, query, setDoc } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React from "react";
import { firestore, storage } from "../../firebase/firebase";
import Navbar from "../components/navbar/Navbar";
import { Meal } from "../meals/Meals";
import Loading from '../loading/Loading';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import { useFormik } from 'formik';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import * as Yup from 'yup';
export default function MealsEditor() {
    const [loading,setLoading]=React.useState<boolean>(true);
    const [meals,setMeals]=React.useState<Meal[]>([]);
    const [addMealDialog,setAddMealDialog]=React.useState<boolean>(false);
    const [selectedMeal,setSelectedMeal]=React.useState<Meal|undefined>(undefined); 
    console.log(meals);
    React.useEffect(()=>{
        const q=query(collection(firestore,"meals"),limit(50));
        const unsubscribe=onSnapshot(q,(querySnapshot)=>{
            const meals:Meal[]=[];
            querySnapshot.forEach((doc)=>{
                meals.push({...doc.data(),docID:doc.id} as Meal);
            });
            if(meals.length>49){
                window.alert("Only 50 meals are shown, please contact admin to view more meals.");
            }
            setMeals(meals);
            setLoading(false);
        })
        return unsubscribe;
    },[]);
    return(
       <>
       <Navbar/>
       <Fab color='info' style={{position:"fixed",right:"1rem",bottom:"1rem"}} onClick={()=>{setAddMealDialog(true)}}>
        <AddIcon/>
       </Fab>
       <AddMealDialog open={addMealDialog} setOpen={setAddMealDialog} meal={selectedMeal} setSelectedMeal={setSelectedMeal}/>
       {
        loading? <Loading fullscreen/>:
        <MealsDiv>
            {meals.map((meal)=>(
                <MealCard meal={meal} setSelectedMeal={setSelectedMeal} setAddMealDialog={setAddMealDialog} key={meal.name}/>
            ))}
        </MealsDiv>
       }
       </>
    );
}

function MealCard({meal,setSelectedMeal,setAddMealDialog}:{meal:Meal,setSelectedMeal:React.Dispatch<React.SetStateAction<Meal|undefined>>,setAddMealDialog:React.Dispatch<React.SetStateAction<boolean>>}){
    const [imageURL,setImageURL]=React.useState<string>("");
    React.useEffect(()=>{
        const storageRef = ref(storage, meal.imagePath);
        getDownloadURL(storageRef).then((url)=>{
            setImageURL(url);
        });
    },[]);
    function deleteMeal({meal}:{meal:Meal}){
      if(window.confirm("Are you sure you want to delete this meal?\nThis action is irrecovrable and will only affect future meal selections.")){
        deleteDoc(doc(firestore,"meals",meal.docID)).then(()=>{
          //NOTE:: removed deleteing the image because the meal can still be in the current menu 
          // const storageRef=ref(storage,meal.imagePath);
          // deleteObject(storageRef).catch((e)=>{console.error(e)});
          window.alert("Meal deleted successfully");
        }).catch((e)=>{
          window.alert("Error deleting meal");
          console.error(e)});
      }
    }
    return(
    <div style={{width:"100%",display:"flex",flexDirection:"column", gap:"0.2rem"}}>
    <div style={{display:"flex",gap:"0.5rem",width:"fit-content"}}>
        <div style={{width:"10%",height:"100%",minWidth:"10rem",minHeight:"15rem"}}>
        {imageURL?<img src={imageURL} alt="avatar" style={{ objectFit: "contain",borderRadius:"1rem",minWidth:"5rem"}} height="90%" width="100%" />:
        <Skeleton variant="rectangular" style={{borderRadius:"1rem",width:"10rem",height:"15rem"}}/>}
        </div>

        <div style={{display:"flex",flexDirection:"column",gap:"0.2rem",width:"fit-content"}}>
            <Typography variant="h6">{meal.name}</Typography>
            <Typography variant="body2" style={{width:"40%"}}>{meal.description}</Typography>
            <Typography variant="body2" component="div"><Typography fontWeight="bold" variant="body2">ingredients:</Typography>{meal.ingredients.toString()}</Typography>
            <Typography variant="body2"> <Typography fontWeight="bold" variant="body2">allergens:</Typography>{meal.allergens.toString()}</Typography>
            <div style={{display:"flex",gap:"0.5rem",marginTop:"0.5rem"}}>
                <Button variant='contained' color='info' onClick={()=>{
                    setSelectedMeal(meal);
                    setAddMealDialog(true);

                }} endIcon={<EditIcon/>}>Edit</Button>
                <Button variant='contained' color='error' endIcon={<DeleteForeverIcon/>} onClick={
                  ()=>{
                    deleteMeal({meal:meal})
                  }
                }>Delete</Button>
            </div>
        </div>

    </div>
    <Divider style={{borderWidth:"2px",width:"100%" ,borderRadius:"0.2rem"}}/>

    </div>

    );
}

const basicIngredients=[
    "Milk",
    "Eggs",
    "Fish",
    "Bread",
    "Torilla",
    "Rice",
    "Pasta",
    "Chicken",
    "Beef",
    "Beans",
    "Cheese",
    "Tomato",
    "Lettuce",
    "Onion",
    "Carrot",
    "Potato",
    "Apple",
    "Orange",
    "Banana",
    "Strawberry",
    "Grapes",
    "Watermelon",
    "Blueberry",
    "Raspberry",
    "Blackberry",
    "Pineapple",
    "Mango",    
];

const allergens:string[]=[
    "Milk",
    "Egg",
    "Peanut/Nuts",
    "Fish",
    "Shellfish",
    "Soy",
    "Wheat",
    "Sesame",
    "Molluscs",
  ]
function AddMealDialog({open,setOpen,meal,setSelectedMeal}:{open:boolean,setOpen:React.Dispatch<React.SetStateAction<boolean>>,meal?:Meal,setSelectedMeal:React.Dispatch<React.SetStateAction<Meal|undefined>>}){
    const [imageURL,setImageURL]=React.useState<string>("");
    const [selectedImage,setSelectedImage]=React.useState<File| null>(null);
    const [submitting,setSubmitting]=React.useState<boolean>(false);
    function compressImage(file:File):Promise<File> {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
      
          reader.onload = (event) => {
            const img = new Image();
            if(!event.target){
                reject(new Error("Event target is empty"));
                return;
            }
            //@ts-ignore
            img.src = event.target.result;
      
            img.onload = () => {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
      
              // Set the maximum width and height for the image
              const maxWidth = 1900;
              const maxHeight = 1200;
      
              let width = img.width;
              let height = img.height;
      
              // Scale down the image if necessary to fit within the maximum width and height
              if (width > maxWidth) {
                height = (height * maxWidth) / width;
                width = maxWidth;
              }
      
              if (height > maxHeight) {
                width = (width * maxHeight) / height;
                height = maxHeight;
              }
      
              canvas.width = width;
              canvas.height = height;
      
              // Draw the image onto the canvas

                if(!ctx){
                    reject(new Error("Canvas is empty"));
                    return;
                }
              ctx.drawImage(img, 0, 0, width, height);
      
              // Convert the canvas image to a compressed file object
              canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        reject(new Error("Canvas is empty"));
                        return;
                    }
                  const compressedFile = new File([blob], file.name, {
                    type: "image/jpeg",
                    lastModified: Date.now(),
                  });
                  resolve(compressedFile);
                },
                "image/jpeg",
                0.7
              );
            };
          };
      
          reader.onerror = (error) => reject(error);
        });
      }
    const mealFormik=useFormik({
        initialValues:meal?meal:{
            docID:"",
            name:"",
            description:"",
            ingredients:[],
            allergens:[],
            imagePath:"",
        },
        validationSchema:Yup.object({
            name:Yup.string().required("Required").max(50,"Invalid name"),
            description:Yup.string().required("Required").max(200,"Invalid description"),
            ingredients:Yup.array().of(Yup.string().required("Required")).required("Required").min(1,"Required"),
            allergens:Yup.array().of(Yup.string().required("Required")).required("Required"),
        }),
        //TODO::test editing and uploading more
        onSubmit:async (values)=>{
        console.log(values);
            setSubmitting(true);
            if(values.docID!==""){
                try{
                    const docRef=doc(firestore,"meals",values.docID);
                    if(selectedImage!==null){
                        const storageRef = ref(storage, `meals/${values.docID}.jpg`);
                        await uploadBytes(storageRef, selectedImage);
                    }
                    await setDoc(docRef,{
                        name:values.name.toLowerCase(),
                        description:values.description,
                        ingredients:values.ingredients.map((ingredient:string)=>ingredient.toLowerCase()),
                        allergens:values.allergens.map((allergen:string)=>allergen.toLowerCase()),
                        imagePath:`meals/${values.docID}.jpg`,
                    },{merge:true});
                                setOpen(false)
          mealFormik.resetForm();
          setSelectedImage(null);
          setSelectedMeal(undefined);
        setImageURL("");
                }catch(e){
                    window.alert("error updating meal");
                    console.error(e);
                }
            }else{
                if(selectedImage===null){
                    window.alert("Please select an image");
                    return;
                }
                try{
                const newDocRef=doc(collection(firestore,"meals"));
                const storageRef = ref(storage, `meals/${newDocRef.id}.jpg`);
                await uploadBytes(storageRef, selectedImage);
                await setDoc(newDocRef,{
                    name:values.name,
                    description:values.description,
                    ingredients:values.ingredients,
                    allergens:values.allergens,
                    imagePath:`meals/${newDocRef.id}.jpg`,
                })
                            setOpen(false)
          mealFormik.resetForm();
          setSelectedImage(null);
          setSelectedMeal(undefined);
        setImageURL("");
            }catch(e){
                window.alert("error creating meal");
                console.error(e);
            }
            }
            setSubmitting(false);
        }
    });
    React.useEffect(()=>{
        if(meal){
            mealFormik.setFieldValue("name",meal.name);
            mealFormik.setFieldValue("description",meal.description);
            mealFormik.setFieldValue("ingredients",meal.ingredients);
            mealFormik.setFieldValue("allergens",meal.allergens);
            mealFormik.setFieldValue("docID",meal.docID);
            mealFormik.setFieldValue("imagePath",meal.imagePath);
            setImageURL("");
            setSelectedImage(null);
            const storageRef = ref(storage, meal.imagePath);
            getDownloadURL(storageRef).then((url)=>{
                setImageURL(url);
            });
        }else{
            mealFormik.resetForm();
            setImageURL("");
            setSelectedImage(null);
        }
    },[meal]);
    return (
      <Dialog open={open} fullWidth component="form" onSubmit={(e)=>{
            e.preventDefault();
            mealFormik.handleSubmit();
      }}>
        <DialogTitle>{meal ? "Edit Meal" : "Add Meal"}</DialogTitle>
        <Divider />
        <DialogContent style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <input
            accept="image/jpeg,image/png,image/webp,image/jpg"
            style={{ display: "none" }}
            capture="environment"
            id="mealImage.upload"
            name="mealImage.upload"
            type="file"
            onChange={async(event) => {
              if (event.target.files && event.target.files[0]) {
                const compressedImage=await compressImage(event.target.files[0]);
                setSelectedImage(compressedImage);
              }
            }}
          />
          <label htmlFor="mealImage.upload">
            <Card style={{ width: "100%", height: "100%" }}>
              <CardActionArea
                style={{ width: "100%", height: "100%" ,display:"flex",justifyContent:"center"}}
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("mealImage.upload")?.click();
                }}
              >
                <CardContent style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" ,width:"80%",height:"100%"}}>                
                  {
                    !meal && !selectedImage? <> <CameraAltIcon color="info" /> 
                    <Typography variant="body2" color="#0288d1">
                      UPLOAD IMAGE
                    </Typography>
                    </>:<img src={selectedImage? URL.createObjectURL(selectedImage) : imageURL} alt="avatar" style={{ objectFit: "contain",borderRadius:"1rem",height:"100%"}} height="10 %" width="100%" />
                  }
                </CardContent>
              </CardActionArea>
            </Card>
          </label>

          <TextField
            variant="outlined"
            label="Meal Name"
            value={mealFormik.values.name}
            name="name"
            id="name"
            onChange={mealFormik.handleChange}
            onBlur={mealFormik.handleBlur}
            helperText={mealFormik.touched.name && mealFormik.errors.name}
            error={mealFormik.touched.name && Boolean(mealFormik.errors.name)}
          />
          <TextField
            variant="outlined"
            label="Description"
            value={mealFormik.values.description}
            name="description"
            id="description"
            onChange={mealFormik.handleChange}
            onBlur={mealFormik.handleBlur}
            helperText={mealFormik.touched.description && mealFormik.errors.description}
            error={mealFormik.touched.description && Boolean(mealFormik.errors.description)}
            multiline
            rows={3}
          />
          <Autocomplete
            multiple
            freeSolo
            id="ingredients"
            options={basicIngredients}
            getOptionLabel={(option) => option}
            defaultValue={[]}
            onChange={(_, value) => {
              mealFormik.setFieldValue("ingredients", value);
            }}
            value={mealFormik.values.ingredients}
            filterSelectedOptions
            renderInput={(params) => <TextField {...params} label="ingredients" 
            error={mealFormik.touched.ingredients && Boolean(mealFormik.errors.ingredients)}
            helperText={mealFormik.touched.ingredients && mealFormik.errors.ingredients}
            />}
            
          />

          <Autocomplete
            multiple
            freeSolo
            id="allergens"
            options={allergens}
            getOptionLabel={(option) => option}
            defaultValue={[]}
            onChange={(event, value) => {
              mealFormik.setFieldValue("allergens", value);
            }}
            value={mealFormik.values.allergens}
            filterSelectedOptions
            renderInput={(params) => <TextField {...params} label="allergens" />}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={() => {
            setOpen(false)
          mealFormik.resetForm();
          setSelectedImage(null);
          setSelectedMeal(undefined);
        setImageURL("");
          }
        } color="error" disabled={submitting}>
            Cancel
          </Button>
          <Button color="info" type='submit' disabled={submitting} >{meal ? "Edit" : "Add"} {submitting && <CircularProgress size="small"/>}</Button>
        </DialogActions>
      </Dialog>
    );
}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }
  
  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));


  const MealsDiv=styled("div")({
    display:"flex",
    alignItems:"flex-start",
    justifyContent:"flex-start",
    flexDirection:"column",
    width:"fit-content",
    height:"100%",
    gap:"1rem",
    margin:"1%",
  });