import React from "react";
import Navbar from "../components/navbar/Navbar"
import { collection, collectionGroup, getCountFromServer, query, where } from "firebase/firestore";
import { firestore } from "../../firebase/firebase";
import { Button, Card, CardContent, Skeleton, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function AdminHome() {
    const [loading,setLoading]=React.useState(true);
    const [numberOfActiveChildren,setNumberOfActiveChildren]=React.useState(0);
    const authContext=useAuth();
    const navigate=useNavigate();
    React.useEffect(()=>{

      if(numberOfActiveChildren!==0){
        console.log("we stopped it baby", numberOfActiveChildren)
        return;
      }
      const childrenCollectionGroup=collectionGroup(firestore,"children");
      const activeChildrenQuery=query(childrenCollectionGroup,where
          ("activeSubscription","==",true));
      getCountFromServer(activeChildrenQuery).then((snapshot)=>{
        console.log("here before 1 ",authContext.currentUser,"\n",authContext.currentUserInfo);
        setNumberOfActiveChildren(snapshot.data().count);
        setLoading(false);
      }
      ).catch((error)=>{
        console.log(error);
        console.log("here in error",authContext.currentUser);
        window.alert("Error fetching data 1");
      });
    },[numberOfActiveChildren])
    return (
      <>
        <Navbar />
        <MainContainer>
          <FullWidthRow>

              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Number of active subscriptions
                  </Typography>

                  {loading?
                  <Skeleton variant="text" width="50%"/>
                  :<Typography variant="body1" color="text.secondary" fontWeight="bold">
                    {numberOfActiveChildren}
                  </Typography>}
                </CardContent>
              </Card>
            
          </FullWidthRow>
          <Button onClick={()=>{navigate("/Admin/newForm")}}> Create new form </Button>
        </MainContainer>
      </>
    );
}
const MainContainer=styled("div")({
    display:"flex",
    flexDirection:"column",
    justifyContent:"flex-start",
    alignItems:"flex-start",
    width:"100%",
    height:"100%",
    marginTop:"2rem"
});
const FullWidthRow=styled("div")({
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    width:"100%",
    height:"100%",
    padding:"2rem",
    boxSizing:"border-box",
});